/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import { AlertTriangle } from "react-feather";
import PropTypes from 'prop-types';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const StyledErrorBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.infoColor.danger};
  border-radius: 5px;
  color: ${({ theme }) => theme.backgroundColor.tertiary};
  display: ${props => props.err ? 'flex' : 'none'};
  font-size: 12px;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 12px 15px;
  width: 100%;

  svg{
    margin-right: 10px;
    width: 20px;
  }
`;


const GPAComponent = ({ error, changed, address, ...field }) => (
  <div>
    <GooglePlacesAutocomplete
      selectProps={{
        ...field,
        isClearable: true,
        styles: {
          control: (provided) => ({
            ...provided,
            background: '#152839'
          }),
          input: (provided) => ({
            ...provided,
            fontWeight: 500,
            color: '#fff',
            padding: '6px 0px'
          }),
          placeholder: (provided) => ({
            ...provided,
            fontWeight: 500,
            fontSize: 14,
            color: '#B6B6BA'
          }),
          option: (provided) => ({
            ...provided,
            background: '#152839',
            color: '#fff',
            fontWeight: 500
          }),
          singleValue: (provided) => ({
            ...provided,
            color: '#fff',
            fontWeight: 500
          }),
          menu: (provided) => ({
            ...provided,
            borderRadius: '10px',
            background: '#152839'
          })
        },
        placeholder: 'Podaj Adres',
        address: address,
        onChange: changed,
      }}

      apiOptions={{ language: 'pl' }}
      autocompletionRequest={{
        componentRestrictions: {
          country: ['pl'],
        }
      }}
    />
    {error && <StyledErrorBox err={error}><AlertTriangle />{error.message}</StyledErrorBox>}
  </div>
);

GPAComponent.propTypes = {
  changed: PropTypes.func.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  address: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.shape({
      placeId: PropTypes.string
    })
  }),
}

GPAComponent.defaultProps = {
  error: null,
  address: null
}

export default GPAComponent
