import * as actionTypes from '../actions/actionTypes';

const InitialState = {
  isLoadingAddress: false,
  address: {},
  addressError: null,

  name: '',
  surname: '',
  email: '',
  phone: '',
  additionalInfo: '',
  cutlery: false,
  userDataError: null,

  locality: '',
  street: '',
  postalCode: '',
  buildingNumber: '',
  localNumber: '',
  placeId: '',

  paymentTypeValue: '',
  paymentTypeName: '',
  paymentError: null,

  deliveryPrice: 0,

  realizationType: 'delivery',

  realizationTimeOption: 'now',
  realizationTime: null,

  consent: false,

  isLoadingOrder: false,
  getOrderError: null,
  order: {}
}

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.CHECK_DELIVERY_ADDRESS_START:
      return {
        ...state,
        isLoadingAddress: true,
        addressError: null,
      }
    case actionTypes.CHECK_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoadingAddress: false,
        deliveryPrice: action.data.price,
        address: action.data.address,
        locality: action.data.addressComponents[2].long_name,
        street: action.data.addressComponents[1].long_name,
        postalCode: action.data.addressComponents[6].long_name,
        buildingNumber: action.data.addressComponents[0].long_name,
        placeId: action.data.address.value.place_id,
      }
    case actionTypes.CHECK_DELIVERY_ADDRESS_FAIL:
      return {
        ...state,
        isLoadingAddress: false,
        addressError: action.error,
        price: 0,
        address: {},
        locality: '',
        street: '',
        postalCode: '',
        buildingNumber: '',
        placeId: '',
      }
    case actionTypes.UPDATE_REALIZATION_TYPE:
      return {
        ...state,
        realizationType: action.data,
        paymentTypeValue: '',
        paymentTypeName: '',
        address: {},
        addressError: null,
        locality: '',
        street: '',
        postalCode: '',
        buildingNumber: '',
        localNumber: '',
        placeId: '',
      }
    case actionTypes.UPDATE_REALIZATION_TIME:
      return {
        ...state,
        realizationTimeOption: action.data.realizationTimeOption,
        realizationTime: action.data.realizationTime
      }
    case actionTypes.CHANGE_PAYMENT_TYPE:
      return {
        ...state,
        paymentTypeValue: action.data.paymentTypeValue,
        paymentTypeName: action.data.paymentTypeName
      }
    case actionTypes.UPDATE_USER_DATA:
      return {
        ...state,
        name: action.data.name,
        surname: action.data.surname,
        email: action.data.email,
        phone: action.data.phone,
        additionalInfo: action.data.additionalInfo,
        cutlery: action.data.cutlery,
      }
    case actionTypes.UPDATE_LOCAL_NUMBER:
      return {
        ...state,
        localNumber: action.localNumber
      }
    case actionTypes.UPDATE_CONSENT:
      return {
        ...state,
        consent: action.consent
      }
    case actionTypes.GET_ORDER_START:
      return {
        ...state,
        isLoadingError: true
      }
    case actionTypes.GET_ORDER_SUCCESS:
      return {
        ...state,
        isLoadingOrder: false,
        order: action.data.order
      }
    case actionTypes.GET_ORDER_FAIL:
      return {
        ...state,
        order: {},
        getOrderError: action.error,
        isLoadingOrder: false
      }
    case actionTypes.UPDATE_ORDER_STATUS:
      return {
        ...state,
        order: { ...state.order, status: action.data }
      }
    default:
      return state;
  }

};

export default reducer;
