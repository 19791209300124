import React, { useEffect } from 'react';
import { io } from "socket.io-client";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { Coins, CheckCircle, HotFood, DeliveryTruck, ThumbsUpLikeStar, ShoppingBag } from "@vectopus/atlas-icons-react";
import * as actions from '../../state/actions/index';
import Loading from '../../components/UI/Loading/Loading';

const breatheAnimation = keyframes`
 0% { height: 50px; width: 50px; }
 50% { height: 60px; width: 60px; }
 100% { height: 50px; width: 50px; }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const OrderStatus = styled.div`
  display: flex;
  align-content: center;
  gap: 1.5rem;
  margin: 40px 0;
  display: ${({ show }) => show ? 'flex' : 'none'};
`;

const OrderStatusItem = styled.div`
  background-color: ${({ theme, active }) => active ? theme.backgroundColor.tertiary : theme.accentColor.primary};
  border-radius: 100px;
  padding: 25px;
  color: ${({ theme, active }) => active ? theme.accentColor.primary : theme.neutralColor.white};
  aspect-ratio: 1/1;
  display: flex;
  flex-grow: 1;
  width: 130px;
  align-items: center;
  justify-content: center;

  svg {
    width: 50px;
    height: 50px;
    animation-name: ${({ active }) => active ? breatheAnimation : null};
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
  }

`;

const OrderStatusInfo = styled.p`
  font-size: 26px;
  font-weight: bold;
`;

const Track = (props) => {
  const { isLoading, order, getOrder, updateOrderStatus } = props;

  const realizationStatus = {
    'awaitingPayment': 'Twoja płatnośc jest przetważana',
    'new': 'Twoje zamówienie zostało przyjęte do realizacji!',
    'confirmed': 'Twoje zamówienie zostało przyjęte do realizacji!',
    'preparing': 'Twoje zamówienie jest przygotowywane',
    'delivery': 'Twoje zamówienie jest w drodze!',
    'ready': 'Twoje zamówienie jest gotowe do odbioru',
    'completed': 'Zamówienie zostało zrealizowane',
    'canceled': 'To zamówienie zostało anulowane',
    'failedPayment': 'Płatność zakończona niepowodzeniem! Twoje zamówienie nie zostało przyjęte'
  }

  const realizationTypes = {
    'pickup': 'Odbióru osobistego',
    'delivery': 'Dostawy'
  }

  useEffect(() => {
    if (localStorage.getItem('orderId')) {
      getOrder(localStorage.getItem('orderId'));
      const socket = io(`${process.env.REACT_APP_API}`);

      socket.emit('create', localStorage.getItem('orderId'));
      socket.on('PaymentStatus', data => {
        updateOrderStatus(data.status);
      });
    }
  }, []);

  return (
    <StyledContainer>
      {
        isLoading || Object.keys(order).length === 0
          ?
          <Loading />
          :
          (
            <>
              <h2>Twoje zamówienie z Może Ryba {order.restaurant.name}</h2>
              <h3>Numer zamówienia: {order.orderNumber}</h3>
              <h4>Wybrałeś opcję: {realizationTypes[order.realizationType]}</h4>
              <OrderStatus
                show={order.status !== 'canceled' && order.status !== 'failedPayment'}
              >
                {
                  order.payment.paymentTypeValue === 'mr-ppo'
                    ?
                    null
                    :
                    (
                      <OrderStatusItem active={order.status === 'awaitingPayment'}>
                        <Coins />
                      </OrderStatusItem>
                    )
                }
                <OrderStatusItem active={order.status === 'confirmed' || order.status === 'new'}>
                  <CheckCircle />
                </OrderStatusItem>
                <OrderStatusItem active={order.status === 'preparing'}>
                  <HotFood />
                </OrderStatusItem>
                {
                  order.realizationType === 'delivery'
                    ?
                    (
                      <OrderStatusItem active={order.status === 'delivery'}>
                        <DeliveryTruck />
                      </OrderStatusItem>
                    )
                    :
                    (
                      <OrderStatusItem active={order.status === 'ready'}>
                        <ShoppingBag />
                      </OrderStatusItem>
                    )
                }
                <OrderStatusItem active={order.status === 'completed'}>
                  <ThumbsUpLikeStar />
                </OrderStatusItem>
              </OrderStatus>
              <OrderStatusInfo>{realizationStatus[order.status]}</OrderStatusInfo>
            </>
          )
      }
    </StyledContainer>
  )
}

const mapStateToProps = state => ({
  isLoading: state.order.isLoadingOrder,
  order: state.order.order,
  error: state.order.getOrderError
});

const mapDispatchToProps = dispatch => ({
  getOrder: (orderId) => dispatch(actions.getOrder(orderId)),
  updateOrderStatus: (orderStatus) => dispatch(actions.updateOrderStatus(orderStatus))
});

Track.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  order: PropTypes.shape({
    payment: PropTypes.shape({
      paymentTypeValue: PropTypes.string
    }),
    realizationType: PropTypes.string,
    status: PropTypes.string,
    orderNumber: PropTypes.number,
    restaurant: PropTypes.shape({
      name: PropTypes.string
    })
  }),
  getOrder: PropTypes.func.isRequired,
  updateOrderStatus: PropTypes.func.isRequired
};

Track.defaultProps = {
  order: {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Track);
