import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const variantOptions = {
  primary: {
    backgroundColor: ({ theme }) => theme.accentColor.primary,
    active: ({ theme }) => theme.accentColor.primaryHover
  },
  alert: {
    backgroundColor: ({ theme }) => theme.infoColor.danger,
    active: '#ef4444'
  },
};

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.accentColor.primary};
  border: none;
  border-radius: ${(props) => props.radius ? `${props.radius}px` : '5px'};
  color: ${({ theme }) => theme.backgroundColor.primary};
  cursor: pointer;
  font-size: ${(props) => props.textSize ? `${props.textSize}px` : '16px'};
  font-weight: 600;
  margin-top: ${(props) => props.marginY ? `${props.marginY}px` : '5px'};
  margin-bottom: ${(props) => props.marginY ? `${props.marginY}px` : '5px'};
  margin-left: ${(props) => props.marginX ? `${props.marginX}px` : '10px'};
  margin-right: ${(props) => props.marginX ? `${props.marginX}px` : '10px'};
  padding-top: ${(props) => props.paddingY ? `${props.paddingY}px` : '10px'};
  padding-bottom: ${(props) => props.paddingY ? `${props.paddingY}px` : '10px'};
  padding-left: ${(props) => props.paddingX ? `${props.paddingX}px` : '20px'};
  padding-right: ${(props) => props.paddingX ? `${props.paddingX}px` : '20px'};
  transition: all .3s ease;

  ${({ variant }) =>
    variant &&
    variantOptions[variant] &&
    css`
      background-color: ${variantOptions[variant].backgroundColor};
      &:hover{
        background: ${variantOptions[variant].active};
      }
   `}
`;

const Button = (props) => {
  const {
    children,
    clicked,
    variant,
    paddingX,
    paddingY,
    marginX,
    marginY,
    textSize,
    radius,
    className
  } = props;

  return (
    <StyledButton
      onClick={clicked}
      variant={variant}
      paddingX={paddingX}
      paddingY={paddingY}
      marginX={marginX}
      marginY={marginY}
      textSize={textSize}
      radius={radius}
      className={className}
    >
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  paddingX: PropTypes.number,
  paddingY: PropTypes.number,
  marginX: PropTypes.number,
  marginY: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  clicked: PropTypes.func,
  variant: PropTypes.string,
  textSize: PropTypes.number,
  radius: PropTypes.number
};

Button.defaultProps = {
  className: null,
  paddingX: null,
  paddingY: null,
  marginX: null,
  marginY: null,
  clicked: null,
  textSize: null,
  variant: 'primary',
  radius: null
};

export default Button;
