import axios from 'axios';
import * as actionTypes from './actionTypes';

export const getRestaurantsStart = () => ({
  type: actionTypes.GET_RESTAURANTS_START
});

export const getRestaurantsSuccess = (data) => ({
  type: actionTypes.GET_RESTAURANTS_SUCCESS,
  data: data
});

export const getRestaurantsFail = (error) => ({
  type: actionTypes.GET_RESTAURANTS_FAIL,
  error: error
});

export const getRestaurants = () => dispatch => {
  dispatch(getRestaurantsStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/api/restaurants`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(getRestaurantsSuccess(res.data));
    })
    .catch(err => {
      dispatch(getRestaurantsFail(err.response.data.message));
    })
};

export const setActiveRestaurant = (id) => ({
  type: actionTypes.SET_ACTIVE_RESTAURANT,
  id
});