import React from 'react';
import { PlusCircle } from 'react-feather';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../UI/Button/Button';

const MenuItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.backgroundColor.primary};
  margin-bottom: 10px;
  padding: 8px 5px;
  gap: 30px;
  align-items: center;
  touch-action: none;
`;

const MenuItemData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  gap: 20px;
`;

const MenuItemContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  flex-basis: 85%;
  gap: 5px;
`;

const MenuItemContentName = styled.p`
  font-size: 18px;
  font-weight: 600;
`;

const MenuItemContentDesc = styled.p`
  font-size: 14px;
`;

const MenuItemManage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const MenuItemManagePrice = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const MenuItemImageWrapper = styled.div`
  flex-basis: 7%;
  display: flex;
  align-items: center;
`;

const MenuItemImg = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 100px;
`;

const MenuItem = (props) => {
  const {
    id,
    name,
    desc,
    image,
    price,
    handleAddToCart
  } = props;

  return (
    <MenuItemRow>
      <MenuItemData>
        <MenuItemImageWrapper>
          <MenuItemImg key={id} src={`${process.env.REACT_APP_API}/upload/${image}`} alt={name} />
        </MenuItemImageWrapper>
        <MenuItemContent>
          <MenuItemContentName>{name}</MenuItemContentName>
          <MenuItemContentDesc>{desc}</MenuItemContentDesc>
        </MenuItemContent>
      </MenuItemData>
      <MenuItemManage>
        <MenuItemManagePrice>{price.toFixed(2)} zł</MenuItemManagePrice>
        <Button clicked={handleAddToCart} paddingX={15} marginX={5}>
          <PlusCircle size={22} style={{ marginRight: '5px' }} /> Dodaj
        </Button>
      </MenuItemManage>
    </MenuItemRow>
  );
};

MenuItem.propTypes = {
  handleAddToCart: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired
}

export default MenuItem;
