import axios from 'axios';
import * as actionTypes from './actionTypes';

export const askForPasswordResetStart = () => ({
  type: actionTypes.ASKFORPASSWORDRESET_START
});

export const askForPasswordResetSuccess = (data) => ({
  type: actionTypes.ASKFORPASSWORDRESET_SUCCESS,
  data: data
});

export const askForPasswordResetFail = (error) => ({
  type: actionTypes.ASKFORPASSWORDRESET_FAIL,
  error: error
});

export const askForPasswordReset = (email) => dispatch => {
  dispatch(askForPasswordResetStart());
  axios.post(`${process.env.REACT_APP_API}/auth/resetpassword`, {
    email: email,
  }).then(res => {
    dispatch(askForPasswordResetSuccess(res.data));
  }).catch(err => {
    dispatch(askForPasswordResetFail(err.response.data.message))
  })
}
