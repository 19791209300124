/* eslint-disable no-undef */
import axios from 'axios';
import * as actionTypes from './actionTypes';

export const verifyStart = () => ({
  type: actionTypes.VERIFY_START
});

export const verifySuccess = () => ({
  type: actionTypes.VERIFY_SUCCESS
});

export const verifyFail = (error) => ({
  type: actionTypes.VERIFY_FAIL,
  error: error
});

export const verify = (code) => dispatch => {
  dispatch(verifyStart());
  axios.get(`${process.env.REACT_APP_API}/auth/verify/${code}`)
    .then(res => {
      console.log(res.data);
      dispatch(verifySuccess());
    })
    .catch(err => {
      dispatch(verifyFail(err.response.data.message));
    })
}
