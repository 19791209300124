import React from 'react';
import styled from 'styled-components';
import SocialIcon from '../UI/SocialIcon/SocialIcon';

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  padding: 20px;
  gap: 1rem;

  p {
    font-size: 12px;
    font-weight: 600;
    }

    a {
        font-size: 12px;
        font-weight: 600;
        transition: all .3s ease-in-out;
    }
`;

const FooterRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const SocialIcons = styled.div`
    display: flex;
    flex-direction: row;
`;

const FooterNav = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 20px 0;
        align-items: center;
    }
`;

const Footer = () => (
    <StyledFooter>
        <FooterRow>
            <SocialIcons>
                <SocialIcon link="https://www.facebook.com/MozeRyba" icon="facebook" color="#4267B2" />
                <SocialIcon link="https://www.instagram.com/moze_ryba_fish_chips/?hl=pl" icon="instagram" color="#E1306C" />
                <SocialIcon link="https://pl.tripadvisor.com/Restaurant_Review-g274823-d13478003-Reviews-Moze_Ryba_Fish_Chips-Bydgoszcz_Kuyavia_Pomerania_Province_Central_Poland.html" icon="tripadvisor" color="#00AF87" />
                <SocialIcon link="https://www.google.com/maps/place/Mo%C5%BCe+Ryba+-+Fish+%26+Chips/@53.1285451,18.0037871,17z/data=!3m1!4b1!4m5!3m4!1s0x470313bf5173f22b:0x6a0693eb3ad7a8d4!8m2!3d53.1285451!4d18.0059758" icon="google" color="#4285F4" />
            </SocialIcons>
            <FooterNav>
                <a href="/">Regulamin</a>
                <a href="/">Polityka Prywatności</a>
                <a href="/">Regulamin Promocji</a>
            </FooterNav>
        </FooterRow>
        <FooterRow>
            <div>
                <p>&copy; mozeryba.pl</p>
            </div>
            <div>
                <p>Realizacja: <a href="https://mmisztela.pl" rel="dofollow">mmisztela.pl</a></p>
            </div>
        </FooterRow>
    </StyledFooter>
);

export default Footer;
