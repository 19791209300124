import axios from 'axios';
import * as actionTypes from './actionTypes';

// get Payment Token

export const getPaymentTokenStart = () => ({
  type: actionTypes.GET_PAYMENT_TOKEN_START
});

export const getPaymentTokenSuccess = (data) => ({
  type: actionTypes.GET_PAYMENT_TOKEN_SUCCESS,
  data: data
});

export const getPaymentTokenFail = (error) => ({
  type: actionTypes.GET_PAYMENT_TOKEN_FAIL,
  error: error
});

export const getPaymentToken = () => dispatch => {
  dispatch(getPaymentTokenStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/api/paymentToken`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((res) => {
      dispatch(getPaymentTokenSuccess(res.data));
      localStorage.setItem('paymentToken', res.data.paymentToken);
    })
    .catch((err) => {
      dispatch(getPaymentTokenFail(err.response.data.message));
    })
}

// get Payment Methods

export const getPaymentMethodsStart = () => ({
  type: actionTypes.GET_PAYMENT_METHODS_START
});

export const getPaymentMethodsSuccess = (data) => ({
  type: actionTypes.GET_PAYMENT_METHODS_SUCCESS,
  data: data
});

export const getPaymentMethodsFail = (error) => ({
  type: actionTypes.GET_PAYMENT_METHODS_FAIL,
  error: error
});

export const getPaymentMethods = (paymentToken) => dispatch => {
  dispatch(getPaymentMethodsStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/api/paymentMethods/${paymentToken}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((res) => {
      dispatch(getPaymentMethodsSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getPaymentMethodsFail(err.response.data.message));
    })

}

// payment ???
