import axios from 'axios';
import * as actionTypes from './actionTypes';

export const signinStart = () => ({
  type: actionTypes.SIGNIN_START
});

export const signinSuccess = (data) => ({
  type: actionTypes.SIGNIN_SUCCESS,
  data: data
});

export const signinFail = (error) => ({
  type: actionTypes.SIGNIN_FAIL,
  error: error
});

export const signin = (email, password, name) => dispatch => {
  dispatch(signinStart());
  axios.put(`${process.env.REACT_APP_API}/auth/signup`, {
    email: email,
    password: password,
    name: name,
  }).then(res => {
    dispatch(signinSuccess(res.data));
  }).catch(err => {
    dispatch(signinFail(err.response.data.data[0].msg))
  })
}
