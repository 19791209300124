import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Check } from 'react-feather';
import Button from '../UI/Button/Button';

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    padding: 8px 12px;
  }
`;

const StyledError = styled.div`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.infoColor.danger};
  color: white;
  padding: 10px 15px;
  font-weight: 600;
  font-size: 12px;
`;

const RadioLabel = styled.label`
  display: grid;
  cursor: pointer;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  grid-gap: 0.5em;
  grid-template-columns: min-content auto;
  align-items: center;
  height: 100%;

  &--disabled {
    color: ${({ theme }) => theme.neutralColor.textSecondary};
  }

  .radio__input{
    display: grid;
    grid-template-areas: "radio";

    > * {
      grid-area: radio;
    }

    input{
      z-index: 10;
      height: 20px;
      opacity: 0;
      cursor: pointer;
      width: 20px;

      &:focus + .radio__control {
        box-shadow: 0 0 0 0.05em ${({ theme }) => theme.accentColor.primary}, 0 0 0.15em 0.1em ${({ theme }) => theme.accentColor.primary};
      }

      &:checked + .radio__control {
        background-color: ${({ theme }) => theme.accentColor.primary};
      }

      &:checked + .radio__control .icon {
        opacity: 1;
      }

      &:disabled + .radio__control {
        color: ${({ theme }) => theme.neutralColor.textSecondary};
      }
    }
    .radio__control{
      background-color: transparent;
      border: 2px solid ${({ theme }) => theme.accentColor.primary};
      border-radius: 5px;
      display: inline-grid;
      height: 20px;
      width: 20px;

      .icon{
        opacity: 0;
        margin-top: -3px;

        svg{
          width: 18px;
        }
      }
    }
  }
  .radio__label{
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
`;

const OptionGroup = styled.div`
  margin-bottom: 25px;
`;

const AddButton = styled(Button)`
&& {
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 15px;
}
`;

const ItemOptions = (props) => {
  const {
    itemId,
    menuItemsOptionsGroups,
    menuItemsOptions,
    handleAddToCartWithOptions,
    closeModal
  } = props;

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = data => {
    const newData = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      const newDataObj = {
        optionGroup: Object.keys(data)[i],
        option: Object.values(data)[i]
      }
      newData.push(newDataObj);
    }

    handleAddToCartWithOptions(newData);
    closeModal();
  };

  const [filteredMenuItemsOptionsGroups, setFilteredMenuItemsOptionsGroups] = useState(() => menuItemsOptionsGroups.filter((item) => item.item === itemId ? item : null));

  useEffect(() => {
    setFilteredMenuItemsOptionsGroups(() => menuItemsOptionsGroups.filter((item) =>
      item.item === itemId ? item : null
    ))
  }, [menuItemsOptionsGroups])

  return (
    <div>
      {
        filteredMenuItemsOptionsGroups.length > 0
          ?
          (
            <form onSubmit={handleSubmit(onSubmit)}>
              {filteredMenuItemsOptionsGroups.map(item => (
                <OptionGroup key={item._id}>
                  <h3>Wybierz {item.name}:</h3>

                  <Options>
                    {menuItemsOptions.filter(option =>
                      option.itemOptionsGroup === item._id ? option : null
                    ).map(option => (
                      <div key={option._id}>
                        <RadioLabel>
                          <span className="radio__label">
                            {option.name}
                          </span>
                          <span className="radio__input">
                            <input
                              {...register(`${option.itemOptionsGroup}`, { required: true })}
                              type="radio"
                              name={option.itemOptionsGroup}
                              value={option._id}
                            />
                          
                            <span className="radio__control">
                              <span className="icon">
                                <Check />
                              </span>
                            </span>
                          </span>
                        </RadioLabel>
                      </div>
                    ))}
                    {errors[item._id]?.type === 'required' 
                    && (<StyledError>Wybierz opcję.</StyledError>)}

                  </Options>
                </OptionGroup>
              ))}
              <AddButton type="submit">
                Dodaj
              </AddButton>
            </form>
          )
          :
          <p>Ten produkt nie posiada opcji</p>
      }
    </div>
  );
};

const mapStateToProps = state => ({
  menuItemsOptionsGroups: state.menu.menuItemsOptionsGroups,
  menuItemsOptions: state.menu.menuItemsOptions
});

ItemOptions.propTypes = {
  itemId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleAddToCartWithOptions: PropTypes.func.isRequired,
  menuItemsOptionsGroups: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string
  })),
  menuItemsOptions: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string
  })),
}

ItemOptions.defaultProps = {
  menuItemsOptionsGroups: [],
  menuItemsOptions: [],
}

export default connect(mapStateToProps, null)(ItemOptions);
