import * as actionTypes from '../actions/actionTypes';

const InitialState = {
  isLoadingToken: false,
  getTokenError: null,

  isLoadingPaymentMethods: false,
  paymentMethods: [],
  getPaymentMethodsError: null,

  paymentError: null
}

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_TOKEN_START:
      return {
        ...state,
        isLoadingToken: true
      }
    case actionTypes.GET_PAYMENT_TOKEN_SUCCESS:
      return {
        ...state,
        isLoadingToken: false
      }
    case actionTypes.GET_PAYMENT_TOKEN_FAIL:
      return {
        ...state,
        isLoadingToken: false,
        getTokenError: action.error
      }
    case actionTypes.GET_PAYMENT_METHODS_START:
      return {
        ...state,
        isLoadingPaymentMethods: true
      }
    case actionTypes.GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        isLoadingPaymentMethods: false,
        paymentMethods: action.data.paymentMethods
      }
    case actionTypes.GET_PAYMENT_METHODS_FAIL:
      return {
        ...state,
        isLoadingPaymentMethods: false,
        getPaymentMethodsError: action.error
      }
    case actionTypes.PAYMENT_START:
      return {
        ...state
      }
    case actionTypes.PAYMENT_SUCCESS:
      return {
        ...state
      }
    case actionTypes.PAYMENT_FAIL:
      return {
        ...state,
        paymentError: action.error
      }
    default:
      return state;
  }

};

export default reducer;
