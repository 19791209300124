import React from 'react';
import styled from 'styled-components';
import Menu from '../../components/Menu/Menu';
import Cart from '../../components/Cart/Cart';
import Info from '../../components/Info/Info';
import InfoMobile from '../../components/Info/InfoMobile';

const Full = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledContainer = styled.div`
  display: flex;
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0 15px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const StyledHome = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const StyledMenu = styled.div`
  flex-basis: 70%;

  @media (max-width: 1024px) {
    flex-basis: 100%;
  }
`;

const StyledCart = styled.div`
  flex-basis: 30%;

  @media (max-width: 1024px) {
    flex-basis: 100%;
  }
`;

const MenuTitle = styled.h2`
  font-size: 28px;
  font-weight: 600;
`;

const Home = () => (
  <Full>
    <Info />
    <InfoMobile />
    <StyledContainer>
      <StyledHome>
        <MenuTitle>Menu</MenuTitle>
        <DataContainer>
          <StyledMenu>
            <Menu />
          </StyledMenu>
          <StyledCart>
            <Cart />
          </StyledCart>
        </DataContainer>
      </StyledHome>
    </StyledContainer>
  </Full>
);

export default Home;
