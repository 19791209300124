export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const ADD = 'ADD';
export const SUBSTRACT = 'SUBSTRACT';

export const STORE_RESULT = 'STORE_RESULT';
export const DELETE_RESULT = 'DELETE_RESULT';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const AUTH_GOOGLE_START = 'AUTH_GOOGLE_START';
export const AUTH_GOOGLE_SUCCESS = 'AUTH_GOOGLE_SUCCESS';
export const AUTH_GOOGLE_FAIL = 'AUTH_GOOGLE_FAIL';

export const SIGNIN_START = 'SIGNIN_START';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';

export const VERIFY_START = 'VERIFY_START';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAIL = 'VERIFY_FAIL';

export const RESENDVERIFICATIONCODE_START = 'RESENDVERIFICATIONCODE_START';
export const RESENDVERIFICATIONCODE_SUCCESS = 'RESENDVERIFICATIONCODE_SUCCESS';
export const RESENDVERIFICATIONCODE_FAIL = 'RESENDVERIFICATIONCODE_FAIL';

export const ASKFORPASSWORDRESET_START = 'ASKFORPASSWORDRESET_START';
export const ASKFORPASSWORDRESET_SUCCESS = 'ASKFORPASSWORDRESET_SUCCESS';
export const ASKFORPASSWORDRESET_FAIL = 'ASKFORPASSWORDRESET_FAIL';

export const RESETPASSWORD_START = 'RESETPASSWORD_START';
export const RESETPASSWORD_SUCCESS = 'RESETPASSWORD_SUCCESS';
export const RESETPASSWORD_FAIL = 'RESETPASSWORD_FAIL';

export const RESETPASSWORDVERIFYCODE_START = 'RESETPASSWORDVERIFYCODE_START';
export const RESETPASSWORDVERIFYCODE_SUCCESS = 'RESETPASSWORDVERIFYCODE_SUCCESS';
export const RESETPASSWORDVERIFYCODE_FAIL = 'RESETPASSWORDVERIFYCODE_FAIL';

export const AUTH_LOGIN_CHECK = 'AUTH_LOGIN_CHECK';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const GET_USER_DATA_START = 'GET_USER_DATA_START';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAIL = 'GET_USER_DATA_FAIL';

export const GET_DECKS_START = 'GET_DECKS_START';
export const GET_DECKS_SUCCESS = 'GET_DECKS_SUCCESS';
export const GET_DECKS_FAIL = 'GET_DECKS_FAIL';

export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const EDIT_USER_DATA_START = 'EDIT_USER_DATA_START';
export const EDIT_USER_DATA_SUCCESS = 'EDIT_USER_DATA_SUCCESS';
export const EDIT_USER_DATA_FAIL = 'EDIT_USER_DATA_FAIL';

export const GET_ADDRESS_DATA_START = 'GET_ADDRESS_DATA_START';
export const GET_ADDRESS_DATA_SUCCESS = 'GET_ADDRESS_DATA_SUCCESS';
export const GET_ADDRESS_DATA_FAIL = 'GET_ADDRESS_DATA_FAIL';

// MENU
export const GET_MENU_CATEGORIES_START = 'GET_MENU_CATEGORIES_START';
export const GET_MENU_CATEGORIES_SUCCESS = 'GET_MENU_CATEGORIES_SUCCESS';
export const GET_MENU_CATEGORIES_FAIL = 'GET_MENU_CATEGORIES_FAIL';

export const GET_MENU_ITEMS_START = 'GET_MENU_ITEMS_START';
export const GET_MENU_ITEMS_SUCCESS = 'GET_MENU_ITEMS_SUCCESS';
export const GET_MENU_ITEMS_FAIL = 'GET_MENU_ITEMS_FAIL';

export const GET_MENU_ITEMS_OPTIONS_GROUPS_START = 'GET_MENU_ITEMS_OPTIONS_GROUPS_START';
export const GET_MENU_ITEMS_OPTIONS_GROUPS_SUCCESS = 'GET_MENU_ITEMS_OPTIONS_GROUPS_SUCCESS';
export const GET_MENU_ITEMS_OPTIONS_GROUPS_FAIL = 'GET_MENU_ITEMS_OPTIONS_GROUPS_FAIL';

export const GET_MENU_ITEMS_OPTIONS_START = 'GET_MENU_ITEMS_OPTIONS_START';
export const GET_MENU_ITEMS_OPTIONS_SUCCESS = 'GET_MENU_ITEMS_OPTIONS_SUCCESS';
export const GET_MENU_ITEMS_OPTIONS_FAIL = 'GET_MENU_ITEMS_OPTIONS_FAIL';

export const ADD_CART_START = 'ADD_CART_START';
export const ADD_CART_SUCCESS = 'ADD_CART_SUCCESS';
export const ADD_CART_FAIL = 'ADD_CART_FAIL';

export const GET_CART_START = 'GET_CART_START';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_FAIL = 'GET_CART_FAIL';

export const ADD_TO_CART_START = 'ADD_TO_CART_START';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAIL = 'ADD_TO_CART_FAIL';

export const INCREMENT_QUANTITY_CART_START = 'INCREMENT_QUANTITY_CART_START';
export const INCREMENT_QUANTITY_CART_SUCCESS = 'INCREMENT_QUANTITY_CART_SUCCESS';
export const INCREMENT_QUANTITY_CART_FAIL = 'INCREMENT_QUANTITY_CART_FAIL';

export const DECREMENT_QUANTITY_CART_START = 'DECREMENT_QUANTITY_CART_START';
export const DECREMENT_QUANTITY_CART_SUCCESS = 'DECREMENT_QUANTITY_CART_SUCCESS';
export const DECREMENT_QUANTITY_CART_FAIL = 'DECREMENT_QUANTITY_CART_FAIL';

export const CLEAR_CART_START = 'CLEAR_CART_START';
export const CLEAR_CART_SUCCESS = 'CLEAR_CART_SUCCESS';
export const CLEAR_CART_FAIL = 'CLEAR_CART_FAIL';

export const REMOVE_FROM_CART_START = 'REMOVE_FROM_CART_START';
export const REMOVE_FROM_CART_SUCCESS = 'REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_FAIL = 'REMOVE_FROM_CART_FAIL';

// Restaurant
export const GET_RESTAURANTS_START = 'GET_RESTAURANTS_START';
export const GET_RESTAURANTS_SUCCESS = 'GET_RESTAURANTS_SUCCESS';
export const GET_RESTAURANTS_FAIL = 'GET_RESTAURANTS_FAIL';

export const SET_ACTIVE_RESTAURANT = 'SET_ACTIVE_RESTAURANT';

// order data
export const CHECK_DELIVERY_ADDRESS_START = 'CHECK_DELIVERY_ADDRESS_START';
export const CHECK_DELIVERY_ADDRESS_SUCCESS = 'CHECK_DELIVERY_ADDRESS_SUCCESS';
export const CHECK_DELIVERY_ADDRESS_FAIL = 'CHECK_DELIVERY_ADDRESS_FAIL';

export const UPDATE_REALIZATION_TYPE = 'UPDATE_REALIZATION_TYPE';

export const UPDATE_REALIZATION_TIME = 'UPDATE_REALIZATION_TIME';

// payment
export const GET_PAYMENT_TOKEN_START = 'GET_PAYMENT_TOKEN_START';
export const GET_PAYMENT_TOKEN_SUCCESS = 'GET_PAYMENT_TOKEN_SUCCESS';
export const GET_PAYMENT_TOKEN_FAIL = 'GET_PAYMENT_TOKEN_FAIL';

export const GET_PAYMENT_METHODS_START = 'GET_PAYMENT_METHODS_START';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAIL = 'GET_PAYMENT_METHODS_FAIL';

export const CHANGE_PAYMENT_TYPE = 'CHANGE_PAYMENT_TYPE';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export const UPDATE_LOCAL_NUMBER = 'UPDATE_LOCAL_NUMBER';

export const UPDATE_CONSENT = 'UPDATE_CONSENT';

export const PLACE_ORDER_START = 'PLACE_ORDER_START';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAIL = 'PLACE_ORDER_FAIL';

export const PAYMENT_START = 'PAYMENT_START';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAIL = 'PAYMENT_FAIL';

export const GET_ORDER_START = 'GET_ORDER_START';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL';

export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS';

// GET HOURS (IS OPEN)
export const GET_HOURS_START = 'GET_HOURS_START';
export const GET_HOURS_SUCCESS = 'GET_HOURS_SUCCESS';
export const GET_HOURS_FAIL = 'GET_HOURS_FAIL';

// RESTAURANT INFO
export const GET_RESTAURANT_INFO_START = 'GET_RESTAURANT_INFO_START';
export const GET_RESTAURANT_INFO_SUCCESS = 'GET_RESTAURANT_INFO_SUCCESS';
export const GET_RESTAURANT_INFO_FAIL = 'GET_RESTAURANT_INFO_FAIL';