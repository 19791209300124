import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UserDataForm from './UserDataForm';
import DeliveryForm from './DeliveryForm';
import ConsentForm from './ConsentForm';
import RealizationTypePicker from './RealizationTypePicker';
import RealizationTime from './RealizationTime';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OrderForm = (props) => {
  const { realizationType } = props;

  return (
    <FormContainer>
      <RealizationTypePicker />
      <UserDataForm />
      {
        realizationType === 'delivery'
          ?
          <DeliveryForm />
          :
          null
      }
      <RealizationTime />
      <ConsentForm />
    </FormContainer>
  );
};

const mapStateToProps = state => ({
  realizationType: state.order.realizationType
});

OrderForm.propTypes = {
  realizationType: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, null)(OrderForm);
