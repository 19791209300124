import * as actionTypes from '../actions/actionTypes';

const InitialState = {
  isLoadingMenuCategories: false,
  menuCategories: [],
  menuCategoriesError: null,

  isLoadingMenuItems: false,
  menuItems: [],
  menuItemsError: null,

  isLoadingMenuItemsOptionsGroups: false,
  menuItemsOptionsGroups: [],
  menuItemsOptionsGroupsError: null,

  isLoadingMenuItemsOptions: false,
  menuItemsOptions: [],
  menuItemsOptionsError: null,
}

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MENU_CATEGORIES_START:
      return {
        ...state,
        isLoadingMenuCategories: true
      }
    case actionTypes.GET_MENU_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoadingMenuCategories: false,
        menuCategories: action.data,
        menuCategoriesError: null
      }
    case actionTypes.GET_MENU_CATEGORIES_FAIL:
      return {
        ...state,
        isLoadingMenuCategories: false,
        menuCategoriesError: action.error
      }
    case actionTypes.GET_MENU_ITEMS_START:
      return {
        ...state,
        isLoadingMenuItems: true
      }
    case actionTypes.GET_MENU_ITEMS_SUCCESS:
      return {
        ...state,
        isLoadingMenuItems: false,
        menuItems: action.data,
        menuItemsError: null
      }
    case actionTypes.GET_MENU_ITEMS_FAIL:
      return {
        ...state,
        isLoadingMenuItems: false,
        menuItemsError: action.error
      }
    case actionTypes.GET_MENU_ITEMS_OPTIONS_GROUPS_START:
      return {
        ...state,
        isLoadingMenuItemsOptionsGroups: true
      }
    case actionTypes.GET_MENU_ITEMS_OPTIONS_GROUPS_SUCCESS:
      return {
        ...state,
        isLoadingMenuItemsOptionsGroups: false,
        menuItemsOptionsGroups: action.data,
        menuItemsOptionsGroupsError: null
      }
    case actionTypes.GET_MENU_ITEMS_OPTIONS_GROUPS_FAIL:
      return {
        ...state,
        isLoadingMenuItemsOptionsGroups: false,
        menuItemsOptionsGroupsError: action.error
      }
    case actionTypes.GET_MENU_ITEMS_OPTIONS_START:
      return {
        ...state,
        isLoadingMenuItemsOptions: true
      }
    case actionTypes.GET_MENU_ITEMS_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoadingMenuItemsOptions: false,
        menuItemsOptions: action.data,
        menuItemsOptionsError: null
      }
    case actionTypes.GET_MENU_ITEMS_OPTIONS_FAIL:
      return {
        ...state,
        isLoadingMenuItemsOptions: false,
        menuItemsOptionsError: action.error
      }
    default:
      return state;
  }
};

export default reducer;
