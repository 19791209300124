import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as actions from '../../state/actions/index';

import ResetPasswordForm from '../../components/Forms/ResetPasswordForm/ResetPasswordForm';


const StyledContainer = styled.div`
  padding: 60px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledHeading = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
`;

const PasswordReset = (props) => {
  const {
    match,
    resetPasswordVerifyCode,
    success,
    resetSuccess,
    userId
  } = props;

  useEffect(() => {
    resetPasswordVerifyCode(match.params.passwordResetCode);
  }, []);

  let elem;
  if (success && !resetSuccess) {
    elem = <StyledContainer>
      <StyledHeading>Reset Password</StyledHeading>
      <ResetPasswordForm userId={userId} />
    </StyledContainer>
  } else if (success && resetSuccess) {
    elem = <StyledContainer>
      <StyledHeading>Password changed succesfully. <Link to={{ pathname: '/' }}>Home Page</Link> </StyledHeading>
    </StyledContainer>
  } else {
    elem = <StyledContainer>
      <StyledHeading>There was an error try again <Link to={{ pathname: '/reset-password' }}>Reset password</Link></StyledHeading>
    </StyledContainer>
  }

  return (
    <div>
      {elem}
    </div>
  )
};

const mapStateToProps = (state) => ({
  success: state.resetPasswordVerifyCode.success,
  resetSuccess: state.resetPassword.success,
  userId: state.resetPasswordVerifyCode.userId
});

const mapDispatchToProps = dispatch => ({
  resetPasswordVerifyCode: (code) => dispatch(actions.resetPasswordVerifyCode(code))
});

PasswordReset.defaultProps = {
  success: false,
  userId: null,
  resetSuccess: false
};

PasswordReset.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      passwordResetCode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  resetPasswordVerifyCode: PropTypes.func.isRequired,
  success: PropTypes.bool,
  resetSuccess: PropTypes.bool,
  userId: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
