import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as actions from '../../state/actions/index';

import Modal from '../UI/Modal/Modal';
import useModal from '../../hooks/useModal/useModal';
import MenuItem from './MenuItem';
import ItemOptions from './ItemOptions';

const MenuItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;
  overflow: hidden;
`;

const MenuItemsList = (props) => {
  const {
    menuItems,
    itemCategoryId,
    addCart,
    addToCart,
    userId
  } = props;

  const [filteredMenuItems, setFilteredMenuItems] = useState(() => menuItems.filter(item => itemCategoryId === item.itemCategory && item.active === true ? item : null).sort((a, b) => a.orderKey - b.orderKey));
  const [isShowingItemOptionsModal, toggleItemOptionsModal] = useModal();
  const [activeItemId, setActiveItemId] = useState();

  useEffect(() => {
    setFilteredMenuItems(() =>
      menuItems.filter(item => itemCategoryId === item.itemCategory && item.active === true ? item : null)
        .sort((a, b) => a.orderKey - b.orderKey))
  }, [menuItems])

  const handleAddToCart = (id) => {
    setActiveItemId(id);

    const menuItemObj = menuItems.filter(item => item._id === id)[0];

    if (!localStorage.getItem('cart')) {
      if (menuItemObj.itemOptionsGroup.length === 0) {
        addCart(window.localStorage.getItem('activeRestaurant'), userId, menuItemObj);
      }
      else {
        toggleItemOptionsModal();
      }
    } else if (localStorage.getItem('cart')) {
      if (menuItemObj.itemOptionsGroup.length === 0) {
        addToCart(localStorage.getItem('cart'), menuItemObj);
      }
      else {
        toggleItemOptionsModal();
      }
    }
  }

  const handleAddToCartWithOptions = (id, options) => {
    setActiveItemId(id);
    const menuItemObj = menuItems.filter(item => item._id === id)[0];

    if (!localStorage.getItem('cart')) {
      addCart(window.localStorage.getItem('activeRestaurant'), userId, menuItemObj, options);

    } else if (localStorage.getItem('cart')) {
      addToCart(localStorage.getItem('cart'), menuItemObj, options);
    }
  }

  return (
    <MenuItemContainer>
      {
        filteredMenuItems.map(item => (
          <MenuItem
            key={item._id}
            id={item._id}
            name={item.name}
            desc={item.desc}
            image={item.image}
            price={item.price}
            handleAddToCart={() => handleAddToCart(item._id)}

          />
        ))
      }

      <Modal
        show={isShowingItemOptionsModal}
        clicked={toggleItemOptionsModal}
      >
        <ItemOptions closeModal={toggleItemOptionsModal} itemId={activeItemId} handleAddToCartWithOptions={(data) => handleAddToCartWithOptions(activeItemId, data)} />
      </Modal>

    </MenuItemContainer>
  );
};

const mapStateToProps = state => ({
  menuItems: state.menu.menuItems,
  userId: state.auth.userId
});

const mapDispatchToProps = dispatch => ({
  addCart: (restaurantId, userId, product, options) => dispatch(actions.addCart(restaurantId, userId, product, options)),
  addToCart: (cartId, product, options) => dispatch(actions.addToCart(cartId, product, options))
});

MenuItemsList.propTypes = {
  userId: PropTypes.string,
  addCart: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    active: PropTypes.bool,
    wrapping: PropTypes.bool
  })),
  itemCategoryId: PropTypes.string.isRequired
}

MenuItemsList.defaultProps = {
  menuItems: [],
  userId: null
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemsList);
