import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ChevronRight } from 'react-feather';
import Button from '../UI/Button/Button';
import MenuItemsList from './MenuItemsList';

const ListItem = styled.div`
  display: flex;
  border-radius: 10px;
  width: 100%;
  padding: 10px 20px;
  flex-direction: column;
  margin-bottom: 15px;
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  overflow: hidden;
  touch-action: none;
`;

const ListItemHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ListItemData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ListItemImg = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 100px;
  margin-right: 20px;
`;

const ToggleIcon = styled(ChevronRight)`
  transition: transform .2s ease-in-out;
  transform: ${props => props.$itemcategorytoggled ? 'rotate(90deg)' : 'rotate(0deg)'};
`;

const ListItemTitle = styled.p`
  font-size: 22px;
  font-weight: bold;
`;

const ItemContainer = styled.div`
  width: 100%;
  transition: all .2s ease-in-out;
  max-height: ${props => props.$itemcategorytoggled ? `${props.$containerheight}px` : '0px'};
`;

const ItemContainerInner = styled.div`
  margin-top: 20px;
`;

const MenuCategoriesListItem = (props) => {
  const {
    id,
    name,
    image,
    handleToggleCategory,
    toggledCategory
  } = props;

  const [isToggledCategory, setIsToggledCategory] = useState();
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.scrollHeight);
  });

  useEffect(() => {
    toggledCategory === id ? setIsToggledCategory(true) : setIsToggledCategory(false);
  }, [toggledCategory])

  return (
    <ListItem>
      <ListItemHeading>
        <ListItemData>
          <ListItemImg key={id} src={`${process.env.REACT_APP_API}/upload/${image}`} alt={name} />
          <ListItemTitle> {name} </ListItemTitle>
        </ListItemData>
        <>
          <Button clicked={handleToggleCategory}>
            <ToggleIcon $itemcategorytoggled={isToggledCategory} size={20} />
          </Button>
        </>
      </ListItemHeading>
      <ItemContainer ref={ref} $containerheight={height} $itemcategorytoggled={isToggledCategory}>
        <ItemContainerInner>
          <MenuItemsList itemCategoryId={id} />
        </ItemContainerInner>
      </ItemContainer>
    </ListItem>
  );
};

MenuCategoriesListItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  handleToggleCategory: PropTypes.func.isRequired,
  toggledCategory: PropTypes.string
}
MenuCategoriesListItem.defaultProps = {
  toggledCategory: null
}

export default MenuCategoriesListItem;
