import React, { useEffect } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ChevronsLeft } from 'react-feather';
import { Link } from 'react-router-dom';
import CartCheckout from '../../components/Cart/CartCheckout';
import OrderForm from '../../components/Forms/OrderForm/OrderForm';
import * as actions from '../../state/actions/index';
import PaymentTypePicker from '../../components/Forms/OrderForm/PaymentTypePicker';
import Loading from '../../components/UI/Loading/Loading';
import Button from '../../components/UI/Button/Button';

const StyledContainer = styled.div`
  display: flex;
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const StyledOrder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: flex-start;
`;

const FirstCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-basis: 70%;
`;

const GoBack = styled(Link)`
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
  align-items: center;
  transition: all .3s ease-in-out;
`;

const SecondCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
`;

const Order = (props) => {
  const {
    getPaymentMethods,
    paymentMethods,
    error,
    isLoading,
    orderData,
    placeOrder
  } = props;

  useEffect(() => {
    getPaymentMethods(localStorage.getItem('paymentToken'));
  }, []);

  const methods = useForm({
    mode: "onChange"
  });

  const onSubmit = data => {
    console.log(data, orderData);
    placeOrder(orderData)
  }

  return (
    <StyledContainer>
      <FormProvider {...methods} >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <StyledOrder>
            <FirstCol>
              <GoBack to="/"> <ChevronsLeft /> Wróć do menu</GoBack>
              <CartCheckout />
              {
                !isLoading
                  ?
                  <PaymentTypePicker paymentMethods={paymentMethods} error={error} />
                  :
                  <Loading />
              }
            </FirstCol>
            <SecondCol>
              <OrderForm />
              <Button type="submit" paddingX={35} paddingY={20} textSize={18} radius={10} marginX={1}>
                Zamawiam i płacę
              </Button>
            </SecondCol>
          </StyledOrder>
        </form>
      </FormProvider>
    </StyledContainer>
  );
};

const mapStateToProps = state => ({
  paymentMethods: state.payment.paymentMethods,
  error: state.payment.getPaymentMethodsError,
  isLoading: state.payment.isLoadingPaymentMethods,
  orderData: state.order
});

const mapDispatchToProps = dispatch => ({
  getPaymentMethods: (paymentToken) => dispatch(actions.getPaymentMethods(paymentToken)),
  placeOrder: (orderData) => dispatch(actions.placeOrder(orderData))
});

Order.propTypes = {
  getPaymentMethods: PropTypes.func.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    brandImageUrl: PropTypes.string,
    name: PropTypes.string
  })),
  placeOrder: PropTypes.func.isRequired,
  orderData: PropTypes.shape({
    name: PropTypes.string
  })
};

Order.defaultProps = {
  paymentMethods: [],
  error: null,
  orderData: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
