import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './state/actions/index';
import Layout from './containers/Layout/Layout';

import Home from './containers/Home/Home';
import Order from './containers/Order/Order';
import Verify from './containers/Verify/Verify';
import User from './containers/User/User';
import AskForPasswordReset from './containers/PasswordReset/AskForPasswordReset';
import PasswordReset from './containers/PasswordReset/PasswordReset';
import Track from './containers/Track/Track';

const App = (props) => {
  const {
    authCheckLogIn,
    getCart,
    getRestaurants,
    getPaymentToken
  } = props;

  useEffect(() => {
    authCheckLogIn();
    getPaymentToken();
    getRestaurants();
    if (localStorage.getItem('cart')) {
      getCart(localStorage.getItem('cart'), localStorage.getItem('activeRestaurant'));
    }
  }, [localStorage.getItem('cart'), localStorage.getItem('activeRestaurant')])

  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/verify/:code" component={Verify} />
          <Route path="/konto" component={User} />
          <Route exact path="/reset-password" component={AskForPasswordReset} />
          <Route path="/reset-password/:passwordResetCode" component={PasswordReset} />
          <Route path="/zamowienie" component={Order} />
          <Route path="/sledz-zamowienie" component={Track} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

const mapDispatchToProps = dispatch => ({
  authCheckLogIn: () => dispatch(actions.authCheckLogIn()),
  getCart: (cartId, restaurantId) => dispatch(actions.getCart(cartId, restaurantId)),
  getRestaurants: () => dispatch(actions.getRestaurants()),
  getPaymentToken: () => dispatch(actions.getPaymentToken())
});

App.propTypes = {
  authCheckLogIn: PropTypes.func.isRequired,
  getCart: PropTypes.func.isRequired,
  getRestaurants: PropTypes.func.isRequired,
  getPaymentToken: PropTypes.func.isRequired,
}

export default connect(null, mapDispatchToProps)(App);
