import React from 'react';
import { connect } from 'react-redux';
import { useFormContext } from "react-hook-form";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AlertTriangle, Check } from 'react-feather';
import * as actions from '../../../state/actions/index';

const StyledErrorBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.infoColor.danger};
  border-radius: 5px;
  color: ${({ theme }) => theme.backgroundColor.tertiary};
  display: ${props => props.err ? 'flex' : 'none'};
  font-size: 12px;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 12px 15px;
  width: 100%;

  svg{
    margin-right: 10px;
    width: 20px;
  }
`;

const StyledFormHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
  width: 100%;
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CheckboxLabel = styled.label`
  display: grid;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  grid-gap: 0.5em;
  grid-template-columns: min-content auto;
  margin-bottom: 20px;

  &--disabled {
    color: ${({ theme }) => theme.neutralColor.textSecondary};
  }

  .checkbox__input{
    display: grid;
    grid-template-areas: "checkbox";

    > * {
      grid-area: checkbox;
    }

    input{
      height: 20px;
      opacity: 0;
      width: 20px;

      &:focus + .checkbox__control {
        box-shadow: 0 0 0 0.05em ${({ theme }) => theme.accentColor.primary}, 0 0 0.15em 0.1em ${({ theme }) => theme.accentColor.primary};
      }

      &:checked + .checkbox__control {
        background-color: ${({ theme }) => theme.accentColor.primary};
      }

      &:checked + .checkbox__control .icon {
        opacity: 1;
      }

      &:disabled + .checkbox__control {
        color: ${({ theme }) => theme.neutralColor.textSecondary};
      }
    }
    .checkbox__control{
      background-color: transparent;
      border: 2px solid ${({ theme, error }) => error ? theme.infoColor.danger : theme.accentColor.primary};
      border-radius: 5px;
      display: inline-grid;
      height: 20px;
      width: 20px;

      .icon{
        opacity: 0;
        margin-top: -3px;

        svg{
          width: 18px;
        }
      }
    }
  }
  .checkbox__label{
    font-size: 14px;
    line-height: 22px;
  }
`;

const ConsentForm = (props) => {
  const {
    errorResponse,
    updateConsent
  } = props;

  const { register, formState: { errors } } = useFormContext();

  const handleUpdateConsent = (e) => {
    updateConsent(e.target.checked);
  }

  return (
    <div>
      <StyledFormHeader>Zgody</StyledFormHeader>

      <StyledForm>
        <CheckboxLabel error={errors.checkbox}>
          <span className="checkbox__input">
            <input
              type="checkbox"
              {...register("consent", { required: true, message: 'Zatwierdź zgody' })}
              onChange={(e) => handleUpdateConsent(e)}
            />
            <span className="checkbox__control">
              <span className="icon">
                <Check />
              </span>
            </span>
          </span>
          <span className="checkbox__label">Zapoznałam(em) się i akceptuję Regulamin i Politykę Prywatności</span>
        </CheckboxLabel>
        {errors.consent &&
          <StyledErrorBox err={errors.consent}><AlertTriangle />Zatwierdź zgody</StyledErrorBox>
        }
        <StyledErrorBox err={errorResponse}><AlertTriangle />{errorResponse}</StyledErrorBox>
      </StyledForm>
    </div>
  );
};

const mapStateToProps = state => ({
  errorResponse: state.order.error,
  price: state.order.price,
  currentAddress: state.order.address,
  isLoading: state.order.isLoadingAddress

});

const mapDispatchToProps = dispatch => ({
  updateConsent: (consent) => dispatch(actions.updateConsent(consent))
})

ConsentForm.propTypes = {
  errorResponse: PropTypes.string,
  updateConsent: PropTypes.func.isRequired
};

ConsentForm.defaultProps = {
  errorResponse: null
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsentForm);
