import axios from 'axios';
import * as actionTypes from './actionTypes';

export const getHoursStart = () => ({
  type: actionTypes.GET_HOURS_START
});

export const getHoursSuccess = (data) => ({
  type: actionTypes.GET_HOURS_SUCCESS,
  data: data
});

export const getHoursFail = (error) => ({
  type: actionTypes.GET_HOURS_FAIL,
  error: error
});

export const getHours = (restaurantId) => dispatch => {
  dispatch(getHoursStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/api/isopen/${restaurantId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(getHoursSuccess(res.data));
    })
    .catch(err => {
      dispatch(getHoursFail(err.response.data.message));
    })
};
