import axios from 'axios';
import * as actionTypes from './actionTypes';

export const resendVerificationCodeStart = () => ({
  type: actionTypes.RESENDVERIFICATIONCODE_START
});

export const resendVerificationCodeSuccess = (data) => ({
  type: actionTypes.RESENDVERIFICATIONCODE_SUCCESS,
  data: data
});

export const resendVerificationCodeFail = (error) => ({
  type: actionTypes.RESENDVERIFICATIONCODE_FAIL,
  error: error
});

export const resendVerificationCode = (email) => dispatch => {
  dispatch(resendVerificationCodeStart());
  axios.put(`${process.env.REACT_APP_API}/auth/resendverificationcode`, {
    email: email,
  }).then(res => {
    dispatch(resendVerificationCodeSuccess(res.data));
  }).catch(err => {
    dispatch(resendVerificationCodeFail(err.response.data.message))
  })
}
