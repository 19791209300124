import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import * as actions from '../../../../state/actions/index';
import MenuCategoriesListItem from './MenuCategoriesListItem';

const ListContainer = styled.div`
  width: 100%;
  margin: 20px 0;
`;

const MenuCategoriesList = (props) => {
  const { menuCategories } = props;

  const [toggledCategory, setToggledCategory] = useState(null);
  const [filteredMenuCategories, setFilteredMenuCategories] = useState(() => [...menuCategories].sort((a, b) => a.orderKey - b.orderKey));

  useEffect(() => {
    setFilteredMenuCategories(() => [...menuCategories].sort((a, b) => a.orderKey - b.orderKey))
  }, [menuCategories])

  const handleToggleCategory = (id) => {
    toggledCategory === id ? setToggledCategory(null) : setToggledCategory(id);
  }

  return (
    <ListContainer>
      {
        filteredMenuCategories.map(menuCategory => (
          <MenuCategoriesListItem
            toggledCategory={toggledCategory}
            handleToggleCategory={() => handleToggleCategory(menuCategory._id)}
            key={menuCategory._id}
            id={menuCategory._id}
            name={menuCategory.name}
            image={menuCategory.image}
          />
        ))
      }
    </ListContainer>
  )
};

const mapStateToProps = state => ({
  menuCategories: state.menu.menuCategories
});

MenuCategoriesList.propTypes = {
  menuCategories: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
  }))
}

MenuCategoriesList.defaultProps = {
  menuCategories: []
}

export default connect(mapStateToProps, null)(MenuCategoriesList);
