import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as actions from '../../state/actions/index';
import MenuCategoriesList from './MenuCategoriesList';
import Loading from '../UI/Loading/Loading';

const MenuContainer = styled.div`
  width: 100%;
`;

const Menu = (props) => {
  const {
    getMenuCategories,
    getMenuItems,
    getMenuItemsOptionsGroups,
    getMenuItemsOptions,
    isLoadingMenuCategories,
    isLoadingMenuItems,
    isLoadingMenuItemsOptionsGroups,
    isLoadingMenuItemsOptions,
    activeRestaurant
  } = props;

  useEffect(() => {
    if(activeRestaurant !== null){
      getMenuCategories(activeRestaurant);
      getMenuItems(activeRestaurant);
      getMenuItemsOptionsGroups(activeRestaurant);
      getMenuItemsOptions(activeRestaurant);
    }
  }, [activeRestaurant])

  return (
    <MenuContainer>
      {
        (!isLoadingMenuCategories && !isLoadingMenuItems && !isLoadingMenuItemsOptionsGroups && !isLoadingMenuItemsOptions)
          ?
          <MenuCategoriesList />
          :
          <Loading />
      }
    </MenuContainer>
  )
};

const mapStateToProps = state => ({
  restaurants: state.restaurant.restaurants,
  isLoadingRestaurants: state.restaurant.isLoadingRestaurants,
  isLoadingMenuCategories: state.menu.isLoadingMenuCategories,
  isLoadingMenuItems: state.menu.isLoadingMenuItems,
  isLoadingMenuItemsOptionsGroups: state.menu.isLoadingMenuItemsOptionsGroups,
  isLoadingMenuItemsOptions: state.menu.isLoadingMenuItemsOptions,
  activeRestaurant: state.restaurant.activeRestaurant
});

const mapDispatchToProps = dispatch => ({
  getMenuCategories: (restaruantId) => dispatch(actions.getMenuCategories(restaruantId)),
  getMenuItems: (restaruantId) => dispatch(actions.getMenuItems(restaruantId)),
  getMenuItemsOptionsGroups: (restaruantId) => dispatch(actions.getMenuItemsOptionsGroups(restaruantId)),
  getMenuItemsOptions: (restaruantId) => dispatch(actions.getMenuItemsOptions(restaruantId)),
});

Menu.propTypes = {
  getMenuCategories: PropTypes.func.isRequired,
  getMenuItems: PropTypes.func.isRequired,
  getMenuItemsOptionsGroups: PropTypes.func.isRequired,
  getMenuItemsOptions: PropTypes.func.isRequired,
  isLoadingMenuCategories: PropTypes.bool.isRequired,
  isLoadingMenuItems: PropTypes.bool.isRequired,
  isLoadingMenuItemsOptionsGroups: PropTypes.bool.isRequired,
  isLoadingMenuItemsOptions: PropTypes.bool.isRequired,
  activeRestaurant: PropTypes.string
}

Menu.defaultProps = {
  activeRestaurant: null
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
