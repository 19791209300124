import React from 'react';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AlertTriangle, Check } from 'react-feather';
import * as actions from '../../../state/actions/index';

const StyledErrorBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.infoColor.danger};
  border-radius: 5px;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  display: ${props => props.err ? 'flex' : 'none'};
  font-size: 12px;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 12px 15px;
  width: 100%;

  svg{
    margin-right: 10px;
    width: 20px;
  }
`;

const StyledFormHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
  width: 100%;
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputField = styled.div`
  margin-bottom: 15px;
  position: relative;
  width: 100%;

  span{
    position: absolute;
    right: 15px;
    top: 10px;

    svg{
      width: 18px;
    }
  }

  div{
    color: ${({ theme }) => theme.infoColor.danger};
    font-size: 12px;
    font-weight: 600;
    margin: 5px 0 0 10px;
  }
`;

const StyledInput = styled.input`
  background-color: #16283f;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  padding: 12px 15px;
  transition: all 0.3s ease-in;
  width: 100%;

  ::placeholder{
    color: #B6B6BA;
    font-weight: 500;
  }

  &:focus{
    box-shadow: 0 0 0 0.05em ${({ theme }) => theme.accentColor.primary}, 0 0 0.15em 0.1em ${({ theme }) => theme.accentColor.primary};
  }

  &:focus-visible{
    outline: ${({ theme }) => theme.accentColor.primary} solid 2px;
  }

  &[type='submit']{
    background: ${({ theme }) => theme.accentColor.primary};
    color: ${({ theme }) => theme.neutralColor.textSecondary};
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    padding: 13px;


    &:hover{
      background: ${({ theme }) => theme.accentColor.secondary};
    }
  }
`;

const CheckboxLabel = styled.label`
  display: grid;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  grid-gap: 0.5em;
  grid-template-columns: min-content auto;
  margin-bottom: 20px;

  &--disabled {
    color: ${({ theme }) => theme.neutralColor.textSecondary};
  }

  .checkbox__input{
    display: grid;
    grid-template-areas: "checkbox";

    > * {
      grid-area: checkbox;
    }

    input{
      height: 20px;
      opacity: 0;
      width: 20px;

      &:focus + .checkbox__control {
        box-shadow: 0 0 0 0.05em ${({ theme }) => theme.accentColor.primary}, 0 0 0.15em 0.1em ${({ theme }) => theme.accentColor.primary};
      }

      &:checked + .checkbox__control {
        background-color: ${({ theme }) => theme.accentColor.primary};
      }

      &:checked + .checkbox__control .icon {
        opacity: 1;
      }

      &:disabled + .checkbox__control {
        color: ${({ theme }) => theme.neutralColor.textSecondary};
      }
    }
    .checkbox__control{
      background-color: transparent;
      border: 2px solid ${({ theme, error }) => error ? theme.infoColor.danger : theme.accentColor.primary};
      border-radius: 5px;
      display: inline-grid;
      height: 20px;
      width: 20px;

      .icon{
        opacity: 0;
        margin-top: -3px;

        svg{
          width: 18px;
        }
      }
    }
  }
  .checkbox__label{
    font-size: 14px;
    line-height: 22px;
  }
`;

const StyledAlertIcon = styled(AlertTriangle)`
  color: ${({ theme }) => theme.infoColor.danger};
`;

const UserDataForm = (props) => {
  const {
    errorResponse,
    updateUserData
  } = props;

  const { register, formState: { errors }, getValues } = useFormContext();

  const handleUpdateUserData = () => {
    const data = getValues();
    updateUserData(data);
  }

  return (
    <div>
      <StyledFormHeader>Twoje Dane</StyledFormHeader>

      <StyledForm>
        <InputField>
          <StyledInput
            placeholder="Imię"
            name="name"
            {...register("name", {
              onChange: handleUpdateUserData,
              required: {
                value: true,
                message: 'Imię jest wymagane'
              },
              maxLength: {
                value: 22,
                message: "Imię jest zbyt długie"
              }
            })}
          />
          <span>{errors.name && <StyledAlertIcon />}</span>
          <div>{errors.name && errors.name.message}</div>
        </InputField>
        <InputField>
          <StyledInput
            placeholder="Nazwisko"
            name="surname"
            {...register("surname", {
              onChange: handleUpdateUserData,
              required: {
                value: true,
                message: 'Nazwisko jest wymagane'
              },
              maxLength: {
                value: 22,
                message: "Nazwisko jest zbyt długie"
              }
            })}
          />
          <span>{errors.surname && <StyledAlertIcon />}</span>
          <div>{errors.surname && errors.surname.message}</div>
        </InputField>
        <InputField>
          <StyledInput
            placeholder="Email"
            {...register("email", {
              onChange: handleUpdateUserData,
              required: 'Email jest wymagany',
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Podany email jest nieprawidłowy"
              },
              maxLength: {
                value: 32,
                message: "Nazwisko jest zbyt długie"
              }
            })}
          />
          <span>{errors.email && <StyledAlertIcon />}</span>
          <div>{errors.email && errors.email.message}</div>
        </InputField>
        <InputField>
          <StyledInput
            placeholder="Telefon"
            {...register("phone", {
              onChange: handleUpdateUserData,
              required: 'Numer telefonu jest wymagany',
              pattern: {
                value: /^[0-9]*$/,
                message: 'Podaj prawidłowy numer telefonu'
              },
              maxLength: {
                value: 10,
                message: "Numer telefonu jest za długi"
              },
              minLength: {
                value: 9,
                message: "Numer telefonu jest za krótki"
              }
            })}
          />
          <span>{errors.phone && <StyledAlertIcon />}</span>
          <div>{errors.phone && errors.phone.message}</div>
        </InputField>
        <InputField>
          <StyledInput
            placeholder="Dodatkowe informacje"
            {...register("additionalInfo", {
              onChange: handleUpdateUserData,
              required: {
                value: false
              },
              maxLength: {
                value: 200,
                message: "Zbyt dużo znaków"
              }
            })}
          />
          <span>{errors.additionalInfo && <StyledAlertIcon />}</span>
          <div>{errors.additionalInfo && errors.additionalInfo.message}</div>
        </InputField>
        <CheckboxLabel error={errors.checkbox} onChange={handleUpdateUserData}>
          <span className="checkbox__input">
            <input
              type="checkbox"
              {...register("cutlery", {
                onChange: handleUpdateUserData,
                required: false
              })} />
            <span className="checkbox__control">
              <span className="icon">
                <Check />
              </span>
            </span>
          </span>
          <span className="checkbox__label">Zapakować sztuśce, sól i serwetki?</span>
        </CheckboxLabel>
        <StyledErrorBox err={errorResponse}><AlertTriangle />{errorResponse}</StyledErrorBox>
      </StyledForm>
    </div>
  );
};


const mapStateToProps = state => ({
  errorResponse: state.order.error,
  price: state.order.price,
  currentAddress: state.order.address,
  isLoading: state.order.isLoadingAddress

});

const mapDispatchToProps = dispatch => ({
  updateUserData: (data) => dispatch(actions.updateUserData(data))
});

UserDataForm.propTypes = {
  errorResponse: PropTypes.string,
  updateUserData: PropTypes.func.isRequired
};

UserDataForm.defaultProps = {
  errorResponse: null
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDataForm);
