/* eslint-disable no-undef */
import axios from 'axios';
import * as actionTypes from './actionTypes';

export const getUserStart = () => ({
  type: actionTypes.GET_USER_START
});

export const getUserSuccess = (data) => ({
  type: actionTypes.GET_USER_SUCCESS,
  data: data
});

export const getUserFail = (error) => ({
  type: actionTypes.GET_USER_FAIL,
  error: error
});

export const getUser = (id) => dispatch => {
  dispatch(getUserStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/api/user/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(getUserSuccess(res.data.user));
    })
    .catch(err => {
      dispatch(getUserFail(err.response.data.message));
    })
}

export const editUserDataStart = () => ({
  type: actionTypes.EDIT_USER_DATA_START
});

export const editUserDataSuccess = (data) => ({
  type: actionTypes.EDIT_USER_DATA_SUCCESS,
  data: data
});

export const editUserDataFail = (error) => ({
  type: actionTypes.EDIT_USER_DATA_FAIL,
  error: error
});

export const editUserData = (userId, data) => dispatch => {
  dispatch(editUserDataStart());

  const token = localStorage.getItem('token');
  axios.put(`${process.env.REACT_APP_API}/api/user/${userId}`, {
    data
  },{
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(editUserDataSuccess(res.data.user));
    })
    .catch(err => {
      dispatch(editUserDataFail(err.response.data.message));
    })
};

export const getAddressDataStart = () => ({
  type: actionTypes.GET_ADDRESS_DATA_START
});

export const getAddressDataSuccess = (data) => ({
  type: actionTypes.GET_ADDRESS_DATA_SUCCESS,
  data: data
});

export const getAddressDataFail = (error) => ({
  type: actionTypes.GET_ADDRESS_DATA_FAIL,
  error: error
});

export const getAddressData = (placeId) => dispatch => {
  dispatch(getAddressDataStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/api/getAddress/${placeId}`, 
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(getAddressDataSuccess(res.data));
    })
    .catch(err => {
      dispatch(getAddressDataFail(err.response.data.message));
    })
}