import * as actionTypes from '../actions/actionTypes';

const InitialState = {
  isLoadingRestaurants: false,
  error: null,
  restaurants: [],
  activeRestaurant: null
}

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RESTAURANTS_START:
      return {
        ...state,
        isLoadingRestaurants: true
      }
    case actionTypes.GET_RESTAURANTS_SUCCESS:
      return {
        ...state,
        restaurants: action.data.restaurants,
        isLoadingRestaurants: false
      }
    case actionTypes.GET_RESTAURANTS_FAIL:
      return {
        ...state,
        restaurants: [],
        isLoadingRestaurants: false
      }
    case actionTypes.SET_ACTIVE_RESTAURANT:
      return{
        ...state,
        activeRestaurant: action.id
      }
    default:
      return state;
  }
};

export default reducer;
