import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AlertTriangle, CheckCircle } from 'react-feather';
import * as actions from '../../../state/actions/index';

const StyledErrorBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.infoColor.danger};
  border-radius: 5px;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  display: ${props => props.err ? 'flex' : 'none'};
  font-size: 12px;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 12px 15px;
  width: 100%;

  svg{
    margin-right: 10px;
    width: 20px;
  }
`;

const StyledSuccessBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.accentColor.primary};
  border-radius: 5px;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  display: ${props => props.success ? 'flex' : 'none'};
  font-size: 12px;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 12px 15px;
  width: 100%;

  svg{
    margin-right: 10px;
    width: 20px;
  }
`;

const StyledFormHeader = styled.div`
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 700;
  width: 100%;
`;

const StyledForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputField = styled.div`
  margin-bottom: 15px;
  position: relative;
  width: 100%;

  span{
    position: absolute;
    right: 15px;
    top: 10px;

    svg{
      width: 18px;
    }
  }

  div{
    color: ${({ theme }) => theme.infoColor.danger};
    font-size: 12px;
    font-weight: 600;
    margin: 5px 0 0 10px;
  }
`;

const StyledInput = styled.input`
  border: none;
  border-radius: 5px;
  background-color: #16283f;
  color: #fff;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  padding: 12px 15px;
  transition: all 0.3s ease-in;
  width: 100%;

  ::placeholder{
    color: #B6B6BA;
  }

  &:focus{
    box-shadow: 0 0 0 0.05em ${({ theme }) => theme.accentColor.primary}, 0 0 0.15em 0.1em ${({ theme }) => theme.accentColor.primary};
  }

  &:focus-visible{
    outline: ${({ theme }) => theme.accentColor.primary} solid 2px;
  }

  &[type='submit']{
    background: ${({ theme }) => theme.accentColor.primary};
    color: ${({ theme }) => theme.neutralColor.textSecondary};
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    padding: 13px;


    &:hover{
      background: ${({ theme }) => theme.accentColor.secondary};
    }
  }
`;

const StyledAlertIcon = styled(AlertTriangle)`
  color: ${({ theme }) => theme.infoColor.danger};
`;

const ResetPasswordForm = (props) => {
  const { onResetPassword, errorResponse, success, userId } = props;
  const { register, formState: { errors }, handleSubmit, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");
  const onSubmit = data => {
    onResetPassword(userId, data.password, data.passwordConfirm);
  }

  return (
    <>
      <StyledFormHeader>Set New Password</StyledFormHeader>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <InputField>
          <StyledInput
            name='password'
            type='password'
            placeholder='Password'
            {...register('password', {
              required: "You must specify a password",
              minLength: {
                value: 6,
                message: "Password must have at least 6 characters"
              }
            })} />
          <span>{errors.password && <StyledAlertIcon />}</span>
          <div>{errors.password && errors.password.message}</div>
        </InputField>
        <InputField>
          <StyledInput
            name='password_repeat'
            type='password'
            placeholder='Repeat Password'
            {...register('password_repeat', {
              validate: value =>
                value === password.current || "The passwords do not match"
            })} />
          <span>{errors.password_repeat && <StyledAlertIcon />}</span>
          <div>{errors.password_repeat && errors.password_repeat.message}</div>
        </InputField>

        <StyledSuccessBox success={success}><CheckCircle />Password changed. LogIn</StyledSuccessBox>
        <StyledErrorBox err={errorResponse}><AlertTriangle />{errorResponse}</StyledErrorBox>
        <StyledInput type='submit' value='Set Password' />
      </StyledForm>
    </>
  );
}

const mapStateToProps = state => ({
  errorResponse: state.resetPassword.error,
  success: state.resetPassword.success
});

const mapDispatchToProps = dispatch => ({
  onResetPassword: (userId, password, passwordConfirm) => dispatch(actions.resetPassword(userId, password, passwordConfirm))
})

ResetPasswordForm.propTypes = {
  onResetPassword: PropTypes.func.isRequired,
  errorResponse: PropTypes.string,
  success: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired
}

ResetPasswordForm.defaultProps = {
  errorResponse: null
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
