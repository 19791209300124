import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  error: null,
  decks: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DECKS_START:
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GET_DECKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        decks: action.data.decks
      }
    case actionTypes.GET_DECKS_FAIL:
      return {
        ...state,
        error: action.error
      }
    default:
      return state;
  }
};

export default reducer;
