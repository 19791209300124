import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ShoppingBag, Truck } from 'react-feather';
import * as actions from '../../../state/actions/index';

const StyledFormHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  width: 100%;
`;

const RealizationPickerContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 10px;
`;

const RealizationTypePick = styled.div`
  cursor: pointer;
  display: flex;
  flex-basis: 50%;
  align-items: center;
  justify-content: center;
  padding: 35px 15px;
  color: ${(props) => props.picked ? props.theme.neutralColor.white : props.theme.neutralColor.textPrimary};
  border: solid ${(props) => props.picked ? props.theme.accentColor.secondary : props.theme.backgroundColor.secondary} 3px;
  border-radius: 10px;
  background: ${(props) => props.picked ? props.theme.accentColor.secondary : props.theme.backgroundColor.secondary};
  transition: all .3s ease-in-out;

  &:hover{
    background: ${({ theme }) => theme.accentColor.secondary};
    color: ${({ theme }) => theme.neutralColor.white};

    & p{
      color: ${({ theme }) => theme.neutralColor.white};
    }
  }

  & p {
    font-size: 18px;
    margin-left: 0.5rem;
    font-weight: 600;
    color: ${(props) => props.picked ? props.theme.neutralColor.white : props.theme.neutralColor.textPrimary};
    text-align: center;
    transition: all .3s ease-in-out;
  }
`;

const RealizationTypePicker = (props) => {
  const {
    updateRealizationType,
    realizationType
  } = props;

  const handleRealizationTypeChange = (type) => {
    updateRealizationType(type);
  };

  return (
    <>
      <StyledFormHeader>Sposób Realizacji</StyledFormHeader>
      <RealizationPickerContainer>
        <RealizationTypePick
          onClick={() => handleRealizationTypeChange('pickup')}
          picked={realizationType === 'pickup'}
        >
          <ShoppingBag /><p>Odbiór</p>
        </RealizationTypePick>
        <RealizationTypePick
          onClick={() => handleRealizationTypeChange('delivery')}
          picked={realizationType === 'delivery'}
        >
          <Truck /><p>Dostawa</p>
        </RealizationTypePick>
      </RealizationPickerContainer>
    </>
  );
};

const mapStateToProps = state => ({
  realizationType: state.order.realizationType
});

const mapDispatchToProps = dispatch => ({
  updateRealizationType: (realizationType) => dispatch(actions.updateRealizationType(realizationType))
})

RealizationTypePicker.propTypes = {
  realizationType: PropTypes.string.isRequired,
  updateRealizationType: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(RealizationTypePicker);
