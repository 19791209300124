import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledIcon = styled.a`
  background-color: ${props => props.bgColor};
  text-decoration: none;
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    height: 14px;
    width: auto;
  }
`;

function SocialIcon(props) {
  const { link, icon, color } = props;

  return (
    <StyledIcon href={link} className="social_icon" rel="noopener noreferrer" target="_blank" bgColor={color} >
      <img className="social_icon__image" src={`${process.env.REACT_APP_URL}/${icon}.png`} alt={icon} />
    </StyledIcon>
  );
}

SocialIcon.propTypes = {
  link: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default SocialIcon;
