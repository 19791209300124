import axios from 'axios';
import * as actionTypes from './actionTypes';

export const resetPasswordStart = () => ({
  type: actionTypes.RESETPASSWORD_START
});

export const resetPasswordSuccess = (data) => ({
  type: actionTypes.RESETPASSWORD_SUCCESS,
  data: data
});

export const resetPasswordFail = (error) => ({
  type: actionTypes.RESETPASSWORD_FAIL,
  error: error
});

export const resetPassword = (userId, password) => dispatch => {
  dispatch(resetPasswordStart());
  axios.put(`${process.env.REACT_APP_API}/auth/resetpassword`, {
    userId: userId,
    password: password
  }).then(res => {
    dispatch(resetPasswordSuccess(res.data));
  }).catch(err => {
    dispatch(resetPasswordFail(err.response.data.message))
  })
}
