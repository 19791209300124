import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ChevronLeft, ChevronRight, Zap, Clock } from 'react-feather';
import * as actions from '../../../state/actions/index';

const StyledFormHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
  width: 100%;
`;

const RealizationTimeContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 10px;
`;

const RealizationTimePick = styled.div`
  cursor: pointer;
  display: flex;
  flex-basis: 50%;
  flex-direction: ${(props) => props.direction === 'row' ? 'row' : 'column'};
  align-items: center;
  justify-content: center;
  padding: 35px 15px;
  color: ${(props) => props.picked ? props.theme.neutralColor.white : props.theme.neutralColor.textPrimary};
  border: solid ${(props) => props.picked ? props.theme.accentColor.secondary : props.theme.backgroundColor.secondary} 3px;
  border-radius: 10px;
  background: ${(props) => props.picked ? props.theme.accentColor.secondary : props.theme.backgroundColor.secondary};
  transition: all .3s ease-in-out;

  &:hover{
    background: ${({ theme }) => theme.accentColor.secondary};
    color: ${({ theme }) => theme.neutralColor.white};

    & p{
      color: ${({ theme }) => theme.neutralColor.white};
    }
  }

  & span {
    display: flex;
    flex-direction: row;
    height: 100%;
    opacity:  ${(props) => props.picked ? 0 : 1};
    transform: ${(props) => props.picked ? 'translateY(-100%)' : 'translateY(0)'};
    transition: all 0.2s ease-out;
  }

  & p {
    font-size: 18px;
    margin-left: 0.5rem;
    font-weight: 600;
    color: ${(props) => props.picked ? props.theme.neutralColor.white : props.theme.neutralColor.textPrimary};
    text-align: center;
    transition: all .3s ease-in-out;
  }
`;

const TimePicker = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 100%;
  left: 0;
  visibility: ${(props) => props.picked ? 'visible' : 'hidden'};
  opacity:  ${(props) => props.picked ? 1 : 0};
  transform: ${(props) => props.picked ? 'translateY(-100%)' : 'translateY(0)'};
  transition: all 0.2s ease-in;

  & p{
    font-size: 18px;
    margin-left: 5px;
    letter-spacing: 1px;
  }
`;

const RealizationTimePickAnimated = styled.div`
  position: relative;
`;

const ActionButton = styled.div`
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: all 0.2s ease-in;

  &:hover{
    background-color: ${({ theme }) => theme.backgroundColor.secondary};
    color: ${({ theme }) => theme.accentColor.secondary};
  }
`;

const RealizationTime = (props) => {
  const {
    realizationTimeOption,
    realizationTime,
    updateRealizationTime
  } = props;

  const [planedEnabled, setPlanedEnabled] = useState(false);
  const [timeVal, setTimeVal] = useState();
  const [tMin, setTMin] = useState();

  // 00:00
  // 23:30 - 84600000
  // one hour 3600000

  const tMax = 84600000;
  const tStep = 15;

  useEffect(() => {
    const date = new Date;
    const timeMs = date.getHours() * 60 * 60000 + date.getMinutes() * 60000; // + one hour
    if (timeMs > tMax) {
      setPlanedEnabled(false);
    } else {
      setPlanedEnabled(true);
    }
    const roundedTime = 900000 * Math.ceil(timeMs / 900000);
    setTMin(roundedTime);
    setTimeVal(roundedTime);
  }, []);

  useEffect(() => {
    updateRealizationTime(realizationTimeOption, timeVal)
  }, [timeVal]);

  const handleRealizationTimeChange = (type, time) => {
    updateRealizationTime(type, time);
  };

  const handleDecrease = (e) => {
    e.stopPropagation();
    const newTimeVal = timeVal - 60000 * tStep;

    if (newTimeVal <= tMin) {
      setTimeVal(tMax);
    } else {
      setTimeVal(newTimeVal);
    }
  }

  const handleIncrease = (e) => {
    e.stopPropagation();
    const newTimeVal = timeVal + 60000 * tStep;

    if (newTimeVal >= tMax) {
      setTimeVal(tMin);
    } else {
      setTimeVal(newTimeVal);
    }
  }


  return (
    <div>
      <StyledFormHeader>Czas Realizacji</StyledFormHeader>
      <RealizationTimeContainer>
        <RealizationTimePick
          direction="row"
          onClick={() => handleRealizationTimeChange('now', null)}
          picked={realizationTimeOption === 'now'}
        >
          <Zap /> <p>Najszybciej</p>
        </RealizationTimePick>
        {
          planedEnabled
            ?
            <RealizationTimePick
              onClick={() => handleRealizationTimeChange('planned', null)}
              picked={realizationTimeOption === 'planned'}
            >
              <RealizationTimePickAnimated>
                <span><Clock /> <p>Zaplanuj</p></span>
                <TimePicker picked={realizationTimeOption === 'planned'}>
                  <ActionButton onClick={(e) => handleDecrease(e)}>
                    <ChevronLeft size={28} />
                  </ActionButton>
                  <p>{Math.floor(timeVal / 3600000)}:{Math.floor((timeVal % 3600000) / 60000) < 10 ? '0' : ''}{Math.floor((timeVal % 3600000) / 60000)} </p>
                  <ActionButton onClick={(e) => handleIncrease(e)}>
                    <ChevronRight size={28} />
                  </ActionButton>
                </TimePicker>
              </RealizationTimePickAnimated>
            </RealizationTimePick>
            :
            null
        }
        {realizationTime}
      </RealizationTimeContainer>
    </div>
  );
};
const mapStateToProps = state => ({
  realizationTimeOption: state.order.realizationTimeOption,
  realziationTime: state.order.realizationTime
});

const mapDispatchToProps = dispatch => ({
  updateRealizationTime: (realizationTimeOption, realizationTime) => dispatch(actions.updateRealizationTime(realizationTimeOption, realizationTime))
})

RealizationTime.propTypes = {
  realizationTimeOption: PropTypes.string.isRequired,
  realizationTime: PropTypes.number,
  updateRealizationTime: PropTypes.func.isRequired
}

RealizationTime.defaultProps = {
  realizationTime: null,
}

export default connect(mapStateToProps, mapDispatchToProps)(RealizationTime);
