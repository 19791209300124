import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AlertTriangle } from 'react-feather';
import GPAComponent from './GPAcomponent';
import * as actions from '../../../state/actions/index';
import Loading from '../../UI/Loading/Loading';

const StyledErrorBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.infoColor.danger};
  border-radius: 5px;
  color: ${({ theme }) => theme.backgroundColor.tertiary};
  display: ${props => props.err ? 'flex' : 'none'};
  font-size: 12px;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 12px 15px;
  width: 100%;

  svg{
    margin-right: 10px;
    width: 20px;
  }
`;

const StyledFormHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
  width: 100%;
`;

const StyledForm = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputField = styled.div`
  margin-bottom: 15px;
  position: relative;
  width: 100%;

  span{
    position: absolute;
    right: 15px;
    top: 10px;

    svg{
      width: 18px;
    }
  }

  div{
    color: ${({ theme }) => theme.infoColor.danger};
    font-size: 12px;
    font-weight: 600;
    margin: 5px 0 0 10px;
  }
`;

const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.backgroundColor.tertiary};
  border: none;
  border-radius: 5px;
  color: #fff;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  padding: 12px 15px;
  transition: all 0.3s ease-in;
  width: 100%;

  ::placeholder{
    color: #B6B6BA;
    font-weight: 500;
  }

  &:focus{
    box-shadow: 0 0 0 0.05em ${({ theme }) => theme.accentColor.primary}, 0 0 0.15em 0.1em ${({ theme }) => theme.accentColor.primary};
  }

  &:focus-visible{
    outline: ${({ theme }) => theme.accentColor.primary} solid 2px;
  }

  &[type='submit']{
    background: ${({ theme }) => theme.accentColor.primary};
    color: ${({ theme }) => theme.neutralColor.textSecondary};
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    padding: 13px;


    &:hover{
      background: ${({ theme }) => theme.accentColor.secondary};
    }
  }
`;

const StyledAlertIcon = styled(AlertTriangle)`
  color: ${({ theme }) => theme.infoColor.danger};
`;

const DeliveryForm = React.forwardRef((props, ref) => {
  const {
    checkDeliveryAddress,
    errorResponse,
    isLoading,
    updateLocalNumber
  } = props;

  const [address, setAddress] = useState({});
  const [localNumber, setLocalNumber] = useState("");

  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue
  } = useFormContext();

  useEffect(() => {
    address && Object.keys(address).length > 0 ?
      (
        checkDeliveryAddress(address.value.place_id, localStorage.getItem('activeRestaurant'), address)
      ) :
      null
  }, [address]);

  const handleUpdateLocalNumber = () => {
    const data = getValues();
    setLocalNumber(data.localNumber);
    updateLocalNumber(data.localNumber);
  }

  const handleAddress = (data) => {
    setAddress(data);
    setValue("location", data);
  }

  return (
    <div>
      <StyledFormHeader>Dane Dostawy</StyledFormHeader>
      {
        isLoading
          ?
          <Loading />
          :
          <>
            <StyledForm>
              <div style={{ width: '100%', marginBottom: '20px' }}>
                <Controller
                  name="location"
                  rules={{
                    required: "Adres dostawy jest wymagany"
                  }}
                  control={control}
                  render={({ field, fieldState }) => (
                    <GPAComponent
                      {...field}
                      error={fieldState.error}
                      address={address}
                      changed={(data) => handleAddress(data)}
                      ref={ref}
                    />
                  )}
                />
              </div>
              <InputField>
                <StyledInput
                  value={localNumber}
                  placeholder="Numer Mieszkania"
                  {...register("localNumber", {
                    onChange: handleUpdateLocalNumber,
                    required: {
                      value: false
                    },
                    maxLength: {
                      value: 10,
                      message: "Za długi numer budynku"
                    },
                  })}
                />
                <span>{errors.localNumber && <StyledAlertIcon />}</span>
                <div>{errors.localNumber && errors.localNumber.message}</div>
              </InputField>
              <StyledErrorBox err={errorResponse}><AlertTriangle />{errorResponse}</StyledErrorBox>
            </StyledForm>
          </>
      }
    </div>
  );
}
);

DeliveryForm.displayName = "DeliveryForm";

const mapStateToProps = state => ({
  errorResponse: state.order.addressError,
  price: state.order.price,
  currentAddress: state.order.address,
  isLoading: state.order.isLoadingAddress,
  realizationType: state.order.realizationType

});

const mapDispatchToProps = dispatch => ({
  checkDeliveryAddress: (deliveryPlaceId, restaurantId, address) => dispatch(actions.checkDeliveryAddress(deliveryPlaceId, restaurantId, address)),
  updateLocalNumber: (localNumber) => dispatch(actions.updateLocalNumber(localNumber))
});


DeliveryForm.propTypes = {
  realizationType: PropTypes.string.isRequired,
  updateLocalNumber: PropTypes.func.isRequired,
  checkDeliveryAddress: PropTypes.func.isRequired,
  errorResponse: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  currentAddress: PropTypes.shape({
    label: PropTypes.string
  })
};

DeliveryForm.defaultProps = {
  errorResponse: null,
  currentAddress: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryForm);
