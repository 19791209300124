import React from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AlertTriangle } from 'react-feather';
import * as actions from '../../../state/actions/index';

const StyledErrorBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.infoColor.danger};
  border-radius: 5px;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  display: ${props => props.err ? 'flex' : 'none'};
  font-size: 12px;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 12px 15px;
  width: 100%;

  svg{
    margin-right: 10px;
    width: 20px;
  }
`;

const StyledFormHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
  width: 100%;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputField = styled.div`
  margin-bottom: 15px;
  position: relative;
  width: 100%;

  span{
    position: absolute;
    right: 15px;
    top: 10px;

    svg{
      width: 18px;
    }
  }

  div{
    color: ${({ theme }) => theme.infoColor.danger};
    font-size: 12px;
    font-weight: 600;
    margin: 5px 0 0 10px;
  }
`;

const StyledInput = styled.input`
  background-color: #16283f;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  padding: 12px 15px;
  transition: all 0.3s ease-in;
  width: 100%;

  ::placeholder{
    color: #B6B6BA;
    font-weight: 500;
  }

  &:focus{
    box-shadow: 0 0 0 0.05em ${({ theme }) => theme.accentColor.primary}, 0 0 0.15em 0.1em ${({ theme }) => theme.accentColor.primary};
  }

  &:focus-visible{
    outline: ${({ theme }) => theme.accentColor.primary} solid 2px;
  }

  &[type='submit']{
    background: ${({ theme }) => theme.accentColor.primary};
    color: ${({ theme }) => theme.neutralColor.textSecondary};
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    padding: 13px;


    &:hover{
      background: ${({ theme }) => theme.accentColor.secondary};
    }
  }
`;

const StyledAlertIcon = styled(AlertTriangle)`
  color: ${({ theme }) => theme.infoColor.danger};
`;

const EditUserDataForm = (props) =>{
  const {
    errorResponse,
    editUserData,
    userId,
    firstName,
    secondName,
    phone
  } = props;

  const { register, handleSubmit, formState: {errors} } = useForm({
    defaultValues: {
      firstName: firstName || 'Imię',
      secondName: secondName || 'Nazwisko',
      phone: phone || 'Numer telefonu'
    }
  });

  // useEffect(() => {
  //   reset(firstName, secondName, phone);
  // }, [firstName, secondName, phone]);

  const onSubmit = data => {
    console.log(data);
    editUserData(userId, data);
  }

  return(
    <div>
      <StyledFormHeader>Twoje Dane</StyledFormHeader>

      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <InputField>
          <StyledInput
            placeholder="Imię"
            name="firstName"
            {...register('firstName', {
              required: {
                value: true,
                message: 'Imię jest wymagane'
              },
              maxLength: {
                value: 22,
                message: "Imię jest zbyt długie"
              }
            })}
          />
          <span>{errors.firstName && <StyledAlertIcon />}</span>
          <div>{errors.firstName && errors.firstName.message}</div>
        </InputField>
        <InputField>
          <StyledInput
            placeholder="Nazwisko"
            name="secondName"
            {...register('secondName', {
              required: {
                value: true,
                message: 'Nazwisko jest wymagane'
              },
              maxLength: {
                value: 22,
                message: "Nazwisko jest zbyt długie"
              }
            })}
          />
          <span>{errors.secondName && <StyledAlertIcon />}</span>
          <div>{errors.secondName && errors.secondName.message}</div>
        </InputField>
        <InputField>
          <StyledInput
            name="phone"
            placeholder="Telefon"
            {...register('phone', {
              required: 'Numer telefonu jest wymagany',
              pattern: {
                value: /^[0-9]*$/,
                message: 'Podaj prawidłowy numer telefonu'
              },
              maxLength: {
                value: 10,
                message: "Numer telefonu jest za długi"
              },
              minLength: {
                value: 9,
                message: "Numer telefonu jest za krótki"
              }
            })}
          />
          <span>{errors.phone && <StyledAlertIcon />}</span>
          <div>{errors.phone && errors.phone.message}</div>
        </InputField>
        <StyledErrorBox err={errorResponse}><AlertTriangle />{errorResponse}</StyledErrorBox>
        <StyledInput type='submit' value='Aktualizuj dane' />
      </StyledForm>
    </div>
  );
};

const mapStateToProps = state => ({
  errorResponse: state.users.updateError,
  firstName: state.users.firstName,
  secondName: state.users.secondName,
  phone: state.users.phone,
  userId: state.auth.userId
});

const mapDispatchToProps = dispatch => ({
  editUserData: (userId, data) => dispatch(actions.editUserData(userId, data))
});

EditUserDataForm.propTypes = {
  errorResponse: PropTypes.string,
  userId: PropTypes.string,
  editUserData: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  secondName: PropTypes.string,
  phone: PropTypes.string
};

EditUserDataForm.defaultProps = {
  errorResponse: null,
  userId: null,
  firstName: null,
  secondName: null,
  phone: null
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserDataForm);