import * as actionTypes from '../actions/actionTypes';

const InitialState = {
  isLoadingCart: false,
  error: null,
  products: [],
  productPrice: 0,
  wrappingPrice: 0,
  fullPrice: 0
}

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_CART_START:
      return {
        ...state,
        isLoadingCart: true
      }
    case actionTypes.ADD_CART_SUCCESS:
      return {
        ...state,
        isLoadingCart: false,
        products: action.data.products,
        productPrice: action.data.productPrice,
        wrappingPrice: action.data.wrappingPrice,
        fullPrice: action.data.fullPrice
      }
    case actionTypes.ADD_CART_FAIL:
      return {
        ...state,
        error: action.error
      }
    case actionTypes.GET_CART_START:
      return {
        ...state,
        isLoadingCart: true
      }
    case actionTypes.GET_CART_SUCCESS:
      return {
        ...state,
        products: action.data.cart.products,
        productPrice: action.data.cart.productPrice,
        wrappingPrice: action.data.cart.wrappingPrice,
        fullPrice: action.data.cart.fullPrice,
        isLoadingCart: false
      }
    case actionTypes.GET_CART_FAIL:
      return {
        ...state,
        products: [],
        error: action.error,
        isLoadingCart: false
      }
    case actionTypes.ADD_TO_CART_START:
      return {
        ...state,
        isLoadingCart: true
      }
    case actionTypes.ADD_TO_CART_SUCCESS:
      return {
        ...state,
        products: action.data.cart.products,
        productPrice: action.data.cart.productPrice,
        wrappingPrice: action.data.cart.wrappingPrice,
        fullPrice: action.data.cart.fullPrice,
        isLoadingCart: false
      }
    case actionTypes.ADD_TO_CART_FAIL:
      return {
        ...state,
        products: [],
        error: action.error,
        isLoadingCart: false
      }
    case actionTypes.INCREMENT_QUANTITY_CART_START:
      return {
        ...state,
        isLoadingCart: true
      }
    case actionTypes.INCREMENT_QUANTITY_CART_SUCCESS:
      return {
        ...state,
        products: action.data.cart.products,
        productPrice: action.data.cart.productPrice,
        wrappingPrice: action.data.cart.wrappingPrice,
        fullPrice: action.data.cart.fullPrice,
        isLoadingCart: false
      }
    case actionTypes.INCREMENT_QUANTITY_CART_FAIL:
      return {
        ...state,
        products: [],
        error: action.error,
        isLoadingCart: false
      }
    case actionTypes.DECREMENT_QUANTITY_CART_START:
      return {
        ...state,
        isLoadingCart: true
      }
    case actionTypes.DECREMENT_QUANTITY_CART_SUCCESS:
      return {
        ...state,
        products: action.data.cart.products,
        productPrice: action.data.cart.productPrice,
        wrappingPrice: action.data.cart.wrappingPrice,
        fullPrice: action.data.cart.fullPrice,
        isLoadingCart: false
      }
    case actionTypes.DECREMENT_QUANTITY_CART_FAIL:
      return {
        ...state,
        products: [],
        error: action.error,
        isLoadingCart: false
      }
    case actionTypes.CLEAR_CART_START:
      return {
        ...state,
        isLoadingCart: true
      }
    case actionTypes.CLEAR_CART_SUCCESS:
      return {
        ...state,
        products: [],
        productPrice: 0,
        wrappingPrice: 0,
        fullPrice: 0,
        isLoadingCart: false
      }
    case actionTypes.CLEAR_CART_FAIL:
      return {
        ...state,
        products: [],
        error: action.error,
        isLoadingCart: false
      }
    case actionTypes.REMOVE_FROM_CART_START:
      return {
        ...state,
        isLoadingCart: true
      }
    case actionTypes.REMOVE_FROM_CART_SUCCESS:
      return {
        ...state,
        products: action.data.cart.products,
        productPrice: action.data.cart.productPrice,
        wrappingPrice: action.data.cart.wrappingPrice,
        fullPrice: action.data.cart.fullPrice,
        isLoadingCart: false
      }
    case actionTypes.REMOVE_FROM_CART_FAIL:
      return {
        ...state,
        products: [],
        error: action.error,
        isLoadingCart: false
      }
    default:
      return state;
  }
};

export default reducer;
