import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { User } from 'react-feather';
import styled from 'styled-components';
import * as actions from '../../state/actions/index';
import Button from '../UI/Button/Button';
import HeroLogo from '../../assets/images/hero-logo.png';
import HeroBgImage from '../../assets/images/hero5.jpg';

import SocialIcon from '../UI/SocialIcon/SocialIcon';
import Dropdown from '../UI/Dropdown/Dropdown';

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 50%), url(${HeroBgImage});
  background-size: cover;
`;

const StyledHeaderHero = styled.div`
  align-items: center;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
`;

const StyledHeaderHeroLogo = styled.div`
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  width: 155px;
  height: 155px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 100px;
  align-items: center;

  & img {
    padding: 15px;
  }
`;

const StyledHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & h1{
    color: #fff;
    text-align: center;
    margin: 15px 0;
    line-height: 1.2;
    font-weight: 600;
    font-size: 28px;
  }
`;

const StyledHeadingH2 = styled.h2`
  color: #fff;
  text-align: center;
  margin: 15px 0;
  line-height: 1.2;
  font-weight: 600;
  font-size: 15px;
`;

const StyledHeaderTopBar = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: space-between;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const StyledHeaderTopBarItem = styled.div`
  padding: 12px 0px;
  display: flex;
`;

const StyledHeaderTopBarItemSocial = styled.div`
  padding: 12px 0px;
  display: flex;
`;

const OpenSign = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  width: 28px;
  height: 28px;
  background-color: ${props => props.open ? 'rgba(34, 197, 94, .5)' : 'rgba(239,68,68,.5)'};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 5px 0 0 8px;

  & div {
    display: flex;
    width: 14px;
    height: 14px;
    background-color: ${props => props.open ? '#22c55e' : '#ef4444'};
    border-radius: 50%;
  }
`;

const UserButton = styled(Link)`
  background-color: ${({ theme }) => theme.accentColor.primary};  
  color: ${({ theme }) => theme.backgroundColor.primary};
  border: none;
  font-weight: 600;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  gap: 0.5rem;
  margin: 5px 10px;
  align-items: center;
  transition: all .3s ease;
  font-size: 16px;
  cursor: pointer;

  &:hover{
    color: ${({ theme }) => theme.backgroundColor.primary};
    background-color: ${({ theme }) => theme.accentColor.primaryHover};
  }
`;

const Header = props => {
  const {
    loginModal,
    signupModal,
    isLoggedIn,
    userName,
    logout,
    isOpen, 
    getHours,
    activeRestaurant
  } = props;

  useEffect(() => {
    if (activeRestaurant) {
      getHours(activeRestaurant);
    }
  }, [activeRestaurant]);

  return (
    <StyledHeader>
      <StyledHeaderTopBar>
        <StyledHeaderTopBarItemSocial>
          <SocialIcon link="https://www.facebook.com/MozeRyba" icon="facebook" color="#4267B2" />
          <SocialIcon link="https://www.instagram.com/moze_ryba_fish_chips/?hl=pl" icon="instagram" color="#E1306C" />
          <SocialIcon link="https://pl.tripadvisor.com/Restaurant_Review-g274823-d13478003-Reviews-Moze_Ryba_Fish_Chips-Bydgoszcz_Kuyavia_Pomerania_Province_Central_Poland.html" icon="tripadvisor" color="#00AF87" />
          <SocialIcon link="https://www.google.com/maps/place/Mo%C5%BCe+Ryba+-+Fish+%26+Chips/@53.1285451,18.0037871,17z/data=!3m1!4b1!4m5!3m4!1s0x470313bf5173f22b:0x6a0693eb3ad7a8d4!8m2!3d53.1285451!4d18.0059758" icon="google" color="#4285F4" />
        </StyledHeaderTopBarItemSocial>
        <StyledHeaderTopBarItem>
          {!isLoggedIn
            ?
            <>
              <Button clicked={loginModal}>Logowanie</Button>
              <Button clicked={signupModal}>Rejestracja</Button>
            </>
            :
            <>
              <UserButton to={{ pathname: `/konto` }}>
                <User /> 
                {userName}
              </UserButton>
              <Button clicked={logout}>Wyloguj się</Button>
            </>
          }
        </StyledHeaderTopBarItem>
      </StyledHeaderTopBar>
      <StyledHeaderHero>
        <StyledHeaderHeroLogo>
          <Link to={{ pathname: `/` }}>
            <img src={HeroLogo} alt="Może Ryba Bydgoszcz" />
          </Link>
          <OpenSign open={isOpen}>
            <div />
          </OpenSign>
        </StyledHeaderHeroLogo>
        <StyledHeading>
          <h1>Może Ryba</h1>
        </StyledHeading>
        <Dropdown />
        <StyledHeadingH2>Fish & Chips, Dorsz Burgery, Krewetki, Sałatki </StyledHeadingH2>
      </StyledHeaderHero>
    </StyledHeader>
  )
};

Header.defaultProps = {
  userName: null,
  activeRestaurant: null
};

Header.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  getHours: PropTypes.func.isRequired,
  loginModal: PropTypes.func.isRequired,
  signupModal: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  userName: PropTypes.string,
  activeRestaurant: PropTypes.string
};

const mapStateToProps = (state) => ({
  activeRestaurant: state.restaurant.activeRestaurant,
  isOpen: state.hours.isOpen,
  isLoggedIn: state.auth.isLoggedIn,
  userName: state.auth.name,
  userId: state.auth.userId,
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(actions.logout()),
  getHours: (restaurantId) => dispatch(actions.getHours(restaurantId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);
