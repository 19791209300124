import React from 'react';
import AskForPasswordResetForm from '../../components/Forms/AskForPasswordResetForm/AskForPasswordResetForm';


const AskForPasswordReset = () => (
  <div>
    <AskForPasswordResetForm />
  </div>
);

export default AskForPasswordReset;
