import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import counterReducer from './reducers/counter';
import resultsReducer from './reducers/results';
import authReducer from './reducers/auth';
import decksReducer from './reducers/decks';
import signinReducer from './reducers/signin';
import verifyReducer from './reducers/verify';
import usersReducer from './reducers/users';
import resendVerificationCodeReducer from './reducers/resendVerificationCode';
import askForPasswordResetReducer from './reducers/askForPasswordReset';
import resetPasswordReducer from './reducers/resetPassword';
import resetPasswordVerifyCodeReducer from './reducers/resetPasswordVerifyCode';
import menuReducer from './reducers/menu';
import cartReducer from './reducers/cart';
import restaurantReducer from './reducers/restaurant';
import orderReducer from './reducers/order';
import paymentReducer from './reducers/payment';
import hoursReducer from './reducers/hours';
import restaurantInfoReducer from './reducers/restaurantInfo';

const rootReducer = combineReducers({
  counter: counterReducer,
  results: resultsReducer,
  auth: authReducer,
  decks: decksReducer,
  signin: signinReducer,
  verify: verifyReducer,
  resendVerificationCode: resendVerificationCodeReducer,
  askForPasswordReset: askForPasswordResetReducer,
  resetPassword: resetPasswordReducer,
  resetPasswordVerifyCode: resetPasswordVerifyCodeReducer,
  users: usersReducer,
  menu: menuReducer,
  cart: cartReducer,
  restaurant: restaurantReducer,
  order: orderReducer,
  payment: paymentReducer,
  hours: hoursReducer,
  restaurantInfo: restaurantInfoReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
