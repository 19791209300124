import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Truck, Package, Info, Clock, MapPin, Mail, CreditCard } from 'react-feather';
import * as actions from '../../state/actions/index';
import Loading from '../UI/Loading/Loading';

const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-shadow: 0px 8px 30px -6px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 8px 30px -6px rgba(66, 68, 90, 1);
    box-shadow: 0px 8px 30px -6px rgba(66, 68, 90, 1);

    @media (max-width: 1024px) {
        display: none;
      }
`;

const InfoBox = styled.div`
    flex-basis: 20%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px 40px 20px 20px;

    p {
        font-size: 13px;
        font-weight: 400;
    }

    div {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        p {
            margin-left: 5px;
        }
    }

    h2 {
        font-size: 16px;
    }

    &::after {
        content: "";
        width: 0;
        height: 0;
        border-color: transparent #152839 transparent transparent;
        border-style: solid;
        border-width: 0 80px 80px 0;
        right: 0;
        top: 0;
        position: absolute;
        -webkit-transition: all .35s ease-in-out;
        transition: all .35s ease-in-out;
    }
`;

const InfoBoxIcon = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: white;
`;

const InfoBlock = (props) => {
    const {
        restaurantInfo, 
        getRestaurantInfo, 
        restaurants,
        activeRestaurant
    } = props;

    useEffect(() => {
        if (activeRestaurant) {
            getRestaurantInfo(activeRestaurant);
        }
    }, [activeRestaurant])

    return(
    Object.keys(restaurantInfo).length > 0 && restaurants.length > 0
    ?
    <InfoContainer>
        <InfoBox>
            <InfoBoxIcon>
                <Info size={25}/>
            </InfoBoxIcon>
            <h2>O nas</h2>
            <p>{restaurantInfo.desc}</p>
        </InfoBox>
        <InfoBox>
            <InfoBoxIcon>
                <MapPin size={25}/>
            </InfoBoxIcon>
            <h2>Lokalizacja</h2>
            <p>{restaurantInfo.localization}</p>
        </InfoBox>
        <InfoBox>
            <InfoBoxIcon>
                <Clock size={25}/>
            </InfoBoxIcon>
            <h2>Godziny Otwarcia</h2>
            <p dangerouslySetInnerHTML={{ __html: restaurantInfo.hours }} />
        </InfoBox>
        <InfoBox>
            <InfoBoxIcon>
                <Truck size={25}/>
            </InfoBoxIcon>
            <h2>Dostawa</h2>
            <div><CreditCard size={22}/> <p>{restaurantInfo.deliverymin}</p></div>
            <div><Truck size={22}/> <p>{restaurantInfo.deliveryprice}</p></div>
            <div><Package size={22}/> <p>{restaurantInfo.packageprice}</p></div>
        </InfoBox>
        <InfoBox>
            <InfoBoxIcon>
                <Mail size={25}/>
            </InfoBoxIcon>
            <h2>Kontakt</h2>
            <p>{restaurantInfo.phone}</p>
            <p>{restaurantInfo.email}</p>
        </InfoBox>
    </InfoContainer>
    :
    <Loading />
)};

const mapStateToProps = state => ({
    restaurantInfo: state.restaurantInfo.restaurantInfo,
    restaurants: state.restaurant.restaurants,
    activeRestaurant: state.restaurant.activeRestaurant,
});

const mapDispatchToProps = dispatch => ({
    getRestaurantInfo: (restaurantId) => dispatch(actions.getRestaurantInfo(restaurantId))
});

InfoBlock.propTypes = {
    getRestaurantInfo: PropTypes.func.isRequired,
    activeRestaurant: PropTypes.string,
    restaurantInfo: PropTypes.shape({
        _id: PropTypes.string,
        desc: PropTypes.string,
        localization: PropTypes.string,
        hours: PropTypes.string,
        deliverymin: PropTypes.string,
        deliveryprice: PropTypes.string,
        packageprice: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string
      }),
      restaurants: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          name: PropTypes.string,
          address: PropTypes.string,
          placeId: PropTypes.string,
          deliveryEnabled: PropTypes.bool,
        })
      )
  }

InfoBlock.defaultProps = {
    restaurantInfo: {},
    restaurants: [],
    activeRestaurant: null
  }

export default connect(mapStateToProps, mapDispatchToProps)(InfoBlock);