import * as actionTypes from '../actions/actionTypes';

const InitialState = {
  isLoadingHours: false,
  error: null,
  isOpen: false,
}

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_HOURS_START:
      return {
        ...state,
        isLoadingHours: true
      }
    case actionTypes.GET_HOURS_SUCCESS:
      return {
        ...state,
        isOpen: action.data.isOpen,
        isLoadingHours: false
      }
    case actionTypes.GET_HOURS_FAIL:
      return {
        ...state,
        isOpen: false,
        isLoadingHours: false
      }
    default:
      return state;
  }
};

export default reducer;
