import axios from 'axios';
import * as actionTypes from './actionTypes';

export const getRestaurantInfoStart = () => ({
  type: actionTypes.GET_RESTAURANT_INFO_START
});

export const getRestaurantInfoSuccess = (data) => ({
  type: actionTypes.GET_RESTAURANT_INFO_SUCCESS,
  data: data
});

export const getRestaurantInfoFail = (error) => ({
  type: actionTypes.GET_RESTAURANT_INFO_FAIL,
  error: error
});

export const getRestaurantInfo = (restaurantId) => dispatch => {
  dispatch(getRestaurantInfoStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/api/restaurantInfo/${restaurantId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => {
      dispatch(getRestaurantInfoSuccess(res.data.restaurantInfo));
    })
    .catch(err => {
      dispatch(getRestaurantInfoFail(err.response.data.message));
    })
};