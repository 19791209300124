import axios from 'axios';
import * as actionTypes from './actionTypes';

export const resetPasswordVerifyCodeStart = () => ({
  type: actionTypes.RESETPASSWORDVERIFYCODE_START
});

export const resetPasswordVerifyCodeSuccess = (data) => ({
  type: actionTypes.RESETPASSWORDVERIFYCODE_SUCCESS,
  data: data
});

export const resetPasswordVerifyCodeFail = (error) => ({
  type: actionTypes.RESETPASSWORDVERIFYCODE_FAIL,
  error: error
});

export const resetPasswordVerifyCode = (code) => dispatch => {
  dispatch(resetPasswordVerifyCodeStart());
  axios.get(`${process.env.REACT_APP_API}/auth/resetpassword/${code}`)
    .then(res => {
      dispatch(resetPasswordVerifyCodeSuccess(res.data));
    }).catch(err => {
      dispatch(resetPasswordVerifyCodeFail(err.response.data.message))
    })
}
