import React, { useEffect } from 'react';
import { Edit, ShoppingBag, ChevronsLeft } from 'react-feather';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as actions from '../../state/actions/index';
import Button from '../../components/UI/Button/Button';
import Modal from '../../components/UI/Modal/Modal';
import useModal from '../../hooks/useModal/useModal';
import EditUserDataForm from '../../components/Forms/UserForms/EditUserDataForm';
import EditDeliveryDataForm from '../../components/Forms/UserForms/EditDeliveryDataForm';

const Full = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const GoBack = styled(Link)`
  font-weight: 600;
  font-size: 18px;
  display: flex;
  margin-bottom: 20px;
  align-self: flex-start;
  justify-content: flex-start;
  align-items: center;
  transition: all .3s ease-in-out;
`;

const StyledContainer = styled.div`
  display: flex;
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const StyledUser = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DataContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;
`;

const UserData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 33%;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  padding: 1.5rem;
`;

const DeliveryData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 33%;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  padding: 1.5rem;
`;

const OrdersData = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 33%;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  padding: 1.5rem;
`;

const DataBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const CartIcon = styled(ShoppingBag)`
 color: ${({ theme }) => theme.accentColor.primary};
 opacity: .6;
 width: 100%;
`;

const EmptyOrders = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 15px;

  p {
    font-weight: 600;
    margin-top: 15px;
  }
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
`;

const DataValue = styled.div`
  font-weight: 600;
`;

const User = (props) => {
  const { 
    userId,
    getUser, 
    name, 
    email,
    phone,
    firstName,
    lastName,
    address,
    localNumber,
    getAddressData,
    addressData
  } = props;

  const [isShowingUserData, toggleUserData] = useModal();
  const [isShowingDeliveryData, toggleDeliveryData] = useModal();

  useEffect(() => {
    if(userId !== null) {
      getUser(userId);
    }
  }, [userId]);

  useEffect(() => {
    if(address !== null){
      getAddressData(address);
    }
  }, [address])

  return (
    <Full>
      <StyledContainer>
        <StyledUser>
          <GoBack to="/"> <ChevronsLeft /> Wróć do menu</GoBack>
          <h1>Twoje konto:</h1>
          <DataContainer>
            <UserData>
              <DataBox>
                <h2>Twoje dane:</h2>
                <Data>
                  <DataRow>
                    <div>Nazwa użytkownika: </div>
                    <DataValue>{name} </DataValue>
                  </DataRow>
                  <DataRow>
                    <div>Imię:</div> 
                    <DataValue>{firstName !== null ? firstName : '-'}</DataValue>
                  </DataRow>
                  <DataRow>
                    <div>Nazwisko:</div> 
                    <DataValue>{lastName !== null ? lastName : '-'}</DataValue>
                  </DataRow>
                  <DataRow>
                    <div>Adres email: </div>
                    <DataValue>{email}</DataValue>
                  </DataRow>
                  <DataRow>
                    <div>Numer telefonu: </div>
                    <DataValue>{phone !== null ? phone : '-'}</DataValue>
                  </DataRow>
                </Data>
              </DataBox>
              <Button clicked={toggleUserData}>
                <Edit /> Edytuj
              </Button>
            </UserData>
            <DeliveryData>
              <DataBox>
                <h2>Dane Dostawy:</h2>
                <Data>
                  <DataRow>
                    <div>Adres:</div>
                    {/* <DataValue>{addressData !== null ? addressData : '-'}</DataValue> */}
                    <DataValue>{addressData !== null ? addressData.formatted_address : '-'}</DataValue>
                    {console.log(addressData)}
                  </DataRow>
                  <DataRow>
                    <div>Numer Mieszkania:</div>
                    <DataValue>{localNumber !== null ? localNumber : '-'}</DataValue>
                  </DataRow>
                </Data>
              </DataBox>
              <Button clicked={toggleDeliveryData}>
                <Edit /> Edytuj
              </Button>
            </DeliveryData>
            <OrdersData>
              <h2>Twoje zamówienia:</h2>
              <EmptyOrders>
              <CartIcon size={86} />
              <p>Brak zamówień</p>
              </EmptyOrders>
            </OrdersData>
          </DataContainer>
        </StyledUser>
      </StyledContainer>

      <Modal show={isShowingUserData} clicked={toggleUserData}>
        <EditUserDataForm />
      </Modal>

      <Modal show={isShowingDeliveryData} clicked={toggleDeliveryData}>
        <EditDeliveryDataForm />
      </Modal>
    </Full>
  )
};

const mapStateToProps = (state) => ({
  name: state.users.name,
  email: state.users.email,
  access: state.users.access,
  avatarUrl: state.users.avatarUrl,
  userId: state.auth.userId,
  phone: state.users.phone,
  firstName: state.users.firstName,
  lastName: state.users.lastName,
  address: state.users.address,
  localNumber: state.users.localNumber,
  addressData: state.users.addressData
});

const mapDispatchToProps = dispatch => ({
  getAddressData: (placeId) => dispatch(actions.getAddressData(placeId)),
  getUser: (id) => dispatch(actions.getUser(id))
});

User.defaultProps = {
  name: null,
  email: null,
  userId: null,
  phone: null,
  firstName: null,
  lastName: null,
  address: null,
  localNumber: null,
  addressData: {}
};

User.propTypes = {
  getAddressData: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  userId: PropTypes.string,
  phone: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  address: PropTypes.string,
  localNumber: PropTypes.string,
  addressData: PropTypes.shape({
      place_id: PropTypes.string,
      formatted_address: PropTypes.string
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(User);
