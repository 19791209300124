import axios from 'axios';
import * as actionTypes from './actionTypes';

export const checkDeliveryAddressStart = () => ({
  type: actionTypes.CHECK_DELIVERY_ADDRESS_START
});

export const checkDeliveryAddressSuccess = (data) => ({
  type: actionTypes.CHECK_DELIVERY_ADDRESS_SUCCESS,
  data: data
});

export const checkDeliveryAddressFail = (error) => ({
  type: actionTypes.CHECK_DELIVERY_ADDRESS_FAIL,
  error: error
});

export const checkDeliveryAddress = (deliveryPlaceId, restaurantId, address) => dispatch => {
  dispatch(checkDeliveryAddressStart());

  const token = localStorage.getItem('token');
  axios.post(`${process.env.REACT_APP_API}/api/checkDelivery`, {
    placeId: deliveryPlaceId,
    restaurantId,
    address
  },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      if (res.data.addressComponents.length < 7) {
        return dispatch(checkDeliveryAddressFail('Nieprawidłowy numer budynku'));
      }
      return dispatch(checkDeliveryAddressSuccess(res.data));
    })
    .catch(err => {
      dispatch(checkDeliveryAddressFail(err.response.data.message));
    })
};

export const updateRealizationType = (realizationType) => dispatch => {
  dispatch({
    type: actionTypes.UPDATE_REALIZATION_TYPE,
    data: realizationType
  })
};

export const updateRealizationTime = (realizationTimeOption, realizationTime) => dispatch => {
  dispatch({
    type: actionTypes.UPDATE_REALIZATION_TIME,
    data: {
      realizationTimeOption,
      realizationTime
    }
  })
};

export const paymentTypeChange = (value, name) => dispatch => {
  dispatch({
    type: actionTypes.CHANGE_PAYMENT_TYPE,
    data: {
      paymentTypeValue: value,
      paymentTypeName: name
    }
  })
};

export const updateUserData = (data) => dispatch => {
  dispatch({
    type: actionTypes.UPDATE_USER_DATA,
    data
  })
};

export const updateLocalNumber = (localNumber) => dispatch => {
  dispatch({
    type: actionTypes.UPDATE_LOCAL_NUMBER,
    localNumber
  })
};

export const updateConsent = (consent) => dispatch => {
  dispatch({
    type: actionTypes.UPDATE_CONSENT,
    consent
  })
};

export const placeOrderStart = () => ({
  type: actionTypes.PLACE_ORDER_START,
});

export const placeOrderSuccess = (data) => ({
  type: actionTypes.PLACE_ORDER_SUCCESS,
  data: data
});

export const placeOrderFail = (error) => ({
  type: actionTypes.PLACE_ORDER_FAIL,
  error: error
});

export const placeOrder = (orderData) => dispatch => {
  dispatch(placeOrderStart());

  const userId = localStorage.getItem('userId');
  const restaurantId = localStorage.getItem('activeRestaurant');
  const cartId = localStorage.getItem('cart');
  const token = localStorage.getItem('token');
  axios.post(`${process.env.REACT_APP_API}/api/placeOrder`,
    {
      orderData,
      cartId,
      restaurantId,
      userId
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(placeOrderSuccess(res.data));
      dispatch(payment(res.data.order));
    })
    .catch(err => {
      dispatch(placeOrderFail(err.response.data.message));
    })
};

export const paymentStart = () => ({
  type: actionTypes.PAYMENT_START,
});

export const paymentSuccess = (data) => ({
  type: actionTypes.PAYMENT_SUCCESS,
  data: data
});

export const paymentFail = (error) => ({
  type: actionTypes.PAYMENT_FAIL,
  error: error
});

export const payment = (order) => dispatch => {
  dispatch(paymentStart());

  if (order.payment.paymentTypeValue === 'mr-ppo') {
    console.log(order.payment.paymentTypeValue);
    console.log(order._id);

    localStorage.setItem('orderId', order._id);
    setTimeout(() => {
      window.location.href = `http://host424213.xce.pl/mr/client/sledz-zamowienie`;
    }, 1000);
  }
  else {
    const token = localStorage.getItem('token');

    axios.post(`${process.env.REACT_APP_API}/api/payment`, {
      paymentToken: localStorage.getItem('paymentToken'),
      paymentType: order.payment.paymentTypeValue,
      finalPrice: order.price.finalPrice,
      orderId: order._id,
      cart: order.cart
    },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        dispatch(paymentSuccess(res.data));
        localStorage.setItem('orderId', order._id);
        setTimeout(() => {
          window.location.href = res.data.data;
        }, 1000);
      })
      .catch(err => {
        dispatch(paymentFail(err.response.data.message));
      })
  }
};

export const getOrderStart = () => ({
  type: actionTypes.GET_ORDER_START,
});

export const getOrderSuccess = (data) => ({
  type: actionTypes.GET_ORDER_SUCCESS,
  data: data
});

export const getOrderFail = (error) => ({
  type: actionTypes.GET_ORDER_FAIL,
  error: error
});

export const getOrder = (orderId) => dispatch => {
  dispatch(getOrderStart());
  console.log(orderId);
  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/api/order/${orderId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(getOrderSuccess(res.data));
    })
    .catch(err => {
      dispatch(getOrderFail(err.response.data.message));
    })
};


export const updateOrderStatus = (orderStatus) => {
  console.log('ACTION DATA:', orderStatus);
  return ({
    type: actionTypes.UPDATE_ORDER_STATUS,
    data: orderStatus
  });
};
