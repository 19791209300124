/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ShoppingBag, Edit3, Plus, Minus, Trash2 } from 'react-feather';
import * as actions from '../../state/actions/index';
import Loading from '../UI/Loading/Loading';
import Button from '../UI/Button/Button';

const StyledCart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  background-color: ${({ theme }) => theme.backgroundColor.secondary};
  width: 100%;
  border-radius: 10px;
  margin: 20px 0;
`;

const CartTitle = styled.h2`
  margin-bottom: 20px;
`;

const CartItemDataCol = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const CartItemData = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const CartItemOptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 5px 0;
`;

const CartItemOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 5px 0;
`;

const CartItemOptionData = styled.div`
  font-size: 12px;
  font-weight: 500;

  & span {
    font-weight: 600;
  }
`;

const CartItemManage = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  & div {
    display: flex;
  }
`;

const CartItemDataQuantity = styled.div`
  margin-right: 10px;

  & p{
    font-weight: 500;
    font-size: 14px;
  }
`;

const CartItemDataName = styled.div`
  & p{
    font-weight: 600;
    font-size: 14px;
  }
`;

const CartItemDataPrice = styled.div`

  & p{
    font-weight: 500;
    font-size: 14px;
  }
`;
const StyledCartSummary = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0 0 0;
`;

const StyledCardItems = styled.div`
  width: 100%;

  & div:last-child {
      border-bottom: none;
    }
`;

const StyledCartItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px #a6a6a6 solid;
`;

const EmptyCart = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  align-items: center;

  & p {
    width: 70%;
    margin-top: 20px;
    text-align: center;
    font-weight: 500;
  }
`;

const CartIcon = styled(ShoppingBag)`
 color: ${({ theme }) => theme.accentColor.primary};
 opacity: .6;
 width: 100%;
`;

const ClearCart = styled.div`
  display: none;
  align-items: center;
  margin-top: 40px;
  cursor: pointer;

  & p{
    margin-left: 5px;
    font-size: 12px;
    font-weight: 600;
  }
`;

const Cart = (props) => {
  const {
    isLoadingCart,
    products,
    productPrice,
    getCart,
    addToCart,
    clearCart,
    // eslint-disable-next-line no-unused-vars
    removeFromCart
  } = props;

  const [sortedProducts, setSortedProducts] = useState([]);

  function compareOptions(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false
    }

    const result = [];

    for (let i = 0; i < arr1.length; i++) {
      if (
        arr1[i].optionGroup._id !== arr2[i].optionGroup._id
        ||
        arr1[i].option._id !== arr2[i].option._id
      ) {
        result.push(false);
        // eslint-disable-next-line no-else-return
      } else if (
        arr1[i].optionGroup._id === arr2[i].optionGroup._id
        &&
        arr1[i].option._id === arr2[i].option._id
      ) {
        result.push(true);
      }
    }
    if(result.filter(item => item === true ? item : null).length === arr1.length){
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (localStorage.getItem('cart')) {
      getCart(localStorage.getItem('cart'), localStorage.getItem('activeRestaurant'));
    }
  }, [])

  useEffect(() => {
    const copyProducts = products.reduce((acc, curr) => {
      if (acc.filter(item => item.product._id === curr.product._id).length === 0) {
        const currCopy = { ...curr };
        currCopy.quantity = 1;
        acc.push(currCopy);
        return acc;
      }
      if (acc.filter(item => item.product._id === curr.product._id).length > 0) {
        const accItem = acc.filter(item => item.product._id === curr.product._id && compareOptions(item.options, curr.options))[0];
        if (accItem && compareOptions(accItem.options, curr.options)) {
          const accCopy = [...acc];
          const duplicateProductIndex = accCopy.findIndex(item => item.product._id === curr.product._id && compareOptions(item.options, curr.options));
          accCopy[duplicateProductIndex].quantity += 1;
          return (accCopy);
        }
        const currCopy = { ...curr };
        currCopy.quantity = 1;
        acc.push(currCopy);
        return acc;
      }
      const accCopy = [...acc];
      const duplicateProductIndex = accCopy.findIndex(item => item.product._id === curr.product._id);
      accCopy[duplicateProductIndex].quantity += 1;
      return (accCopy);
    }, []);
    // eslint-disable-next-line no-nested-ternary
    setSortedProducts(copyProducts.sort((a, b) => (a.product.name > b.product.name) ? 1 : ((b.product.name > a.product.name) ? -1 : 0)));
  }, [products])

  const handleIncrement = (item) => {
    // convert item.options to proper format
    const itemOptionsArr = item.options.map(option => ({
      optionGroup: option.optionGroup._id,
      option: option.option._id
    }));
    addToCart(localStorage.getItem('cart'), item.product, itemOptionsArr);
  }

  const handleDecrement = (item) => {
    const itemsArr = [item._id];
    removeFromCart(localStorage.getItem('cart'), itemsArr);
  }

  const handleRemove = (item) => {
    const filteredProductsArr = products.filter(product => product.product._id === item.product._id && compareOptions(product.options, item.options));
    const itemsArr = filteredProductsArr.map(product => product._id);
    removeFromCart(localStorage.getItem('cart'), itemsArr);
  }

  const handleClear = () => {
    clearCart(localStorage.getItem('cart'));
    localStorage.removeItem('cart');
  }

  const CartData = (
    sortedProducts.length > 0
      ?
      (<>
        <StyledCardItems>
          {sortedProducts.map(item => (
            <StyledCartItem key={item._id}>
              <CartItemData>
                <CartItemDataCol>
                  <CartItemDataQuantity><p>{item.quantity}x</p></CartItemDataQuantity>
                  <CartItemDataName><p>{item.product.name}</p></CartItemDataName>
                </CartItemDataCol>
                <CartItemDataPrice><p>{item.product.price.toFixed(2)} zł</p></CartItemDataPrice>
              </CartItemData>
              {
                item.options.length > 0
                  ?
                  <CartItemOptionContainer>
                    {item.options.map((optionItem) => (
                      <CartItemOption key={optionItem.optionGroup._id}>
                        <CartItemOptionData>
                          {optionItem.optionGroup.name}:
                          <span> {optionItem.option.name}</span>
                        </CartItemOptionData>
                      </CartItemOption>
                    ))}
                  </CartItemOptionContainer>
                  :
                  null
              }
              <CartItemManage>
                <div>
                  <Button
                    type='button'
                    clicked={() => handleIncrement(item)}
                    paddingX={8}
                    paddingY={8}
                    marginX={1}
                  >
                    <Plus size={16} />
                  </Button>
                  <Button
                    type='button'
                    clicked={() => handleDecrement(item)}
                    paddingX={8}
                    paddingY={8}
                    marginX={10}
                  >
                    <Minus size={16} />
                  </Button>
                </div>
                <Button
                  type='button'
                  variant="alert"
                  clicked={() => handleRemove(item)}
                  paddingX={8}
                  paddingY={8}
                  marginX={1}
                >
                  <Trash2 size={16} />
                </Button>
              </CartItemManage>
            </StyledCartItem>
          ))}
        </StyledCardItems>
        <div>
          <ClearCart onClick={() => handleClear()}><Edit3 size={18} /><p> Opróżnij koszyk</p></ClearCart>
        </div>
        <StyledCartSummary>
          <NavLink to='/zamowienie'>
            <Button textSize={20} paddingX={35} paddingY={16} radius={10}>Zamów za {productPrice.toFixed(2)} zł</Button>
          </NavLink>
        </StyledCartSummary>
      </>
      )
      :
      (
        <EmptyCart>
          <CartIcon size={86} />
          <p>Brak produktów w koszyku! Dodaj pierwszy produkt.</p>
        </EmptyCart>
      )
  );

  return (
    <StyledCart>
      <CartTitle>Twoje zamówienie</CartTitle>
      {
        isLoadingCart
          ?
          <>
            <Loading />
            <StyledCartSummary>
              <Button paddingX={35} paddingY={16} textSize={18} radius={10}>Zamów za {productPrice.toFixed(2)} zł</Button>
            </StyledCartSummary>
          </>
          :
          CartData
      }
    </StyledCart>
  );
};

const mapStateToProps = state => ({
  isLoadingCart: state.cart.isLoadingCart,
  products: state.cart.products,
  productPrice: state.cart.productPrice,
  wrappingPrice: state.cart.wrappingPrice,
  fullPrice: state.cart.fullPrice
});

const mapDispatchToProps = dispatch => ({
  getCart: (cartId, restaurantId) => dispatch(actions.getCart(cartId, restaurantId)),
  incrementQuantity: (cartId, productId) => dispatch(actions.incrementQuantity(cartId, productId)),
  decrementQuantity: (cartId, productId) => dispatch(actions.decrementQuantity(cartId, productId)),
  clearCart: (cartId) => dispatch(actions.clearCart(cartId)),
  removeFromCart: (cartId, productId) => dispatch(actions.removeFromCart(cartId, productId)),
  addToCart: (cartId, product, options) => dispatch(actions.addToCart(cartId, product, options))
});

Cart.propTypes = {
  isLoadingCart: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    quantity: PropTypes.number,
    product: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      desc: PropTypes.string,
      image: PropTypes.string,
      price: PropTypes.number
    }),
  })),
  productPrice: PropTypes.number,
  getCart: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  clearCart: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired
}

Cart.defaultProps = {
  products: [],
  productPrice: 0,
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
