import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
// import GoogleLogin from 'react-google-login';
import styled from 'styled-components';
import { Check, AlertTriangle, CheckCircle } from 'react-feather';
import * as actions from '../../../state/actions/index';

const StyledErrorBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.infoColor.danger};
  border-radius: 5px;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  display: ${props => props.err ? 'flex' : 'none'};
  font-size: 12px;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 12px 15px;
  width: 100%;

  svg{
    margin-right: 10px;
    width: 20px;
  }
`;

const StyledSuccessBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.accentColor.primary};
  border-radius: 5px;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  display: ${props => props.success ? 'flex' : 'none'};
  font-size: 12px;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 12px 15px;
  width: 100%;

  svg{
    margin-right: 10px;
    width: 20px;
  }
`;

const StyledFormHeader = styled.div`
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 700;
  width: 100%;
`;

const StyledFormFooter = styled.div`
  font-size: 12px;
  text-align: center;
  width: 100%;

  button{
    border: none;
    color: ${({ theme }) => theme.accentColor.primary};
    cursor: pointer;
    text-decoration: none;

    &:hover{
      color:  ${({ theme }) => theme.accentColor.secondary};
    }
  }
`;

const StyledForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputField = styled.div`
  margin-bottom: 15px;
  position: relative;
  width: 100%;

  span{
    position: absolute;
    right: 15px;
    top: 10px;

    svg{
      width: 18px;
    }
  }

  div{
    color: ${({ theme }) => theme.infoColor.danger};
    font-size: 12px;
    font-weight: 600;
    margin: 5px 0 0 10px;
  }
`;

const StyledInput = styled.input`
  border: none;
  border-radius: 5px;
  background-color: #16283f;
  color: #fff;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  padding: 12px 15px;
  transition: all 0.3s ease-in;
  width: 100%;

  ::placeholder{
    color: #B6B6BA;
  }

  &:focus{
    box-shadow: 0 0 0 0.05em ${({ theme }) => theme.accentColor.primary}, 0 0 0.15em 0.1em ${({ theme }) => theme.accentColor.primary};
  }

  &:focus-visible{
    outline: ${({ theme }) => theme.accentColor.primary} solid 2px;
  }

  &[type='submit']{
    background: ${({ theme }) => theme.accentColor.primary};
    color: ${({ theme }) => theme.neutralColor.textSecondary};
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    padding: 13px;


    &:hover{
      background: ${({ theme }) => theme.accentColor.secondary};
    }
  }
`;

const StyledAlertIcon = styled(AlertTriangle)`
  color: ${({ theme }) => theme.infoColor.danger};
`;

const CheckboxLabel = styled.label`
  display: grid;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  grid-gap: 0.5em;
  grid-template-columns: min-content auto;
  margin-bottom: 20px;

  &--disabled {
    color: ${({ theme }) => theme.neutralColor.textSecondary};
  }

  .checkbox__input{
    display: grid;
    grid-template-areas: "checkbox";

    > * {
      grid-area: checkbox;
    }

    input{
      height: 20px;
      opacity: 0;
      width: 20px;

      &:focus + .checkbox__control {
        box-shadow: 0 0 0 0.05em ${({ theme }) => theme.accentColor.primary}, 0 0 0.15em 0.1em ${({ theme }) => theme.accentColor.primary};
      }

      &:checked + .checkbox__control {
        background-color: ${({ theme }) => theme.accentColor.primary};
      }

      &:checked + .checkbox__control .icon {
        opacity: 1;
      }

      &:disabled + .checkbox__control {
        color: ${({ theme }) => theme.neutralColor.textSecondary};
      }
    }
    .checkbox__control{
      background-color: transparent;
      border: 2px solid ${({ theme, error }) => error ? theme.infoColor.danger : theme.accentColor.primary};
      border-radius: 5px;
      display: inline-grid;
      height: 20px;
      width: 20px;

      .icon{
        opacity: 0;
        margin-top: -3px;

        svg{
          width: 18px;
        }
      }
    }
  }
  .checkbox__label{
    font-size: 14px;
    line-height: 22px;
  }
`;

const SignupForm = (props) => {
  const { 
    onSignin, 
    // onGoogleAuth, 
    errorResponse, 
    signedIn, 
    loggedIn, 
    success 
  } = props;
  const { register, formState: { errors }, handleSubmit, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");
  const onSubmit = data => {
    onSignin(data.email, data.password, data.name);
  }

  const handleRedirect = () => {
    signedIn();
    loggedIn();
  }

  // const handleGoogleLogin = googleData => {
  //   onGoogleAuth(googleData);
  // }

  return (
    <>
      <StyledFormHeader>Zarejestruj się</StyledFormHeader>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <InputField>
          <StyledInput
            name='name'
            placeholder='Nazwa uzytkownika'
            {...register('name', {
              required: 'Podaj nazwe użytkownika'
            })} />
          <span>{errors.name && <StyledAlertIcon />}</span>
          <div>{errors.name && errors.name.message}</div>
        </InputField>
        <InputField>
          <StyledInput
            name='email'
            placeholder='Email'
            {...register('email', {
              required: "Email jest wymagany",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Podaj prawidłowy adres email"
              }
            })} />
          <span>{errors.email && <StyledAlertIcon />}</span>
          <div>{errors.email && errors.email.message}</div>
        </InputField>
        <InputField>
          <StyledInput
            name='password'
            type='password'
            placeholder='Hasło'
            {...register('password', {
              required: "Musisz podac hasło",
              minLength: {
                value: 6,
                message: "Hasło musi mieć minimum 6 znaków"
              }
            })} />
          <span>{errors.password && <StyledAlertIcon />}</span>
          <div>{errors.password && errors.password.message}</div>
        </InputField>
        <InputField>
          <StyledInput
            name='password_repeat'
            type='password'
            placeholder='Powtórz hasło'
            {...register('password_repeat', {
              validate: value =>
                value === password.current || "Hasła nie sa takie same"
            })} />
          <span>{errors.password_repeat && <StyledAlertIcon />}</span>
          <div>{errors.password_repeat && errors.password_repeat.message}</div>
        </InputField>

        <CheckboxLabel error={errors.checkbox}>
          <span className="checkbox__input">
            <input type="checkbox" name="checkbox" {...register('checkbox', { required: true })} />
            <span className="checkbox__control">
              <span className="icon">
                <Check />
              </span>
            </span>
          </span>
          <span className="checkbox__label">Rejestrując się akceptujesz <a href='/#'>Regulamin </a> i <a href='/#'>Politykę Prywatności</a></span>
        </CheckboxLabel>
        <StyledSuccessBox success={success}><CheckCircle />Konto utworzone. Sprawdź email i aktywuj konto.</StyledSuccessBox>
        <StyledErrorBox err={errorResponse}><AlertTriangle />{errorResponse}</StyledErrorBox>
        <StyledInput type='submit' value='Zarejestruj się' />
      </StyledForm>
      {/* <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText="Log in with Google"
        onSuccess={handleGoogleLogin}
        onFailure={handleGoogleLogin}
        cookiePolicy='single_host_origin'
      /> */}
      <StyledFormFooter>Masz już konto? <button type="button" onClick={handleRedirect}>Zaloguj się</button></StyledFormFooter>
    </>
  );
}

const mapStateToProps = state => ({
  errorResponse: state.signin.error,
  success: state.signin.success
});

const mapDispatchToProps = dispatch => ({
  onSignin: (email, password, name) => dispatch(actions.signin(email, password, name)),
  onGoogleAuth: (googleData) => dispatch(actions.authGoogle(googleData))
})

SignupForm.propTypes = {
  onGoogleAuth: PropTypes.func.isRequired,
  onSignin: PropTypes.func.isRequired,
  errorResponse: PropTypes.string,
  signedIn: PropTypes.func.isRequired,
  loggedIn: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired
}

SignupForm.defaultProps = {
  errorResponse: null
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
