import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useFormContext } from "react-hook-form";
import { Check, AlertTriangle, CreditCard } from 'react-feather';
import * as actions from '../../../state/actions/index';

const StyledErrorBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.infoColor.danger};
  border-radius: 5px;
  color: ${({ theme }) => theme.backgroundColor.tertiary};
  display: ${props => props.err ? 'flex' : 'none'};
  font-size: 12px;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 12px 15px;
  width: 100%;

  svg{
    margin-right: 10px;
    width: 20px;
  }
`;

const StyledFormHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
  width: 100%;
`;

const PaymentTypePickerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
      width: 100%;
    margin-bottom: 2rem;

  & div {
    flex-basis: 50%;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.backgroundColor.secondary};
    padding: 20px 45px;
    font-size: 18px;
    font-weight: 600;
  }
`;

const StyledForm = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CheckboxLabel = styled.label`
cursor: pointer;
  display: grid;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  grid-gap: 0.5em;
  grid-template-columns: min-content auto;
  align-items: center;
  height: 100%;

  &--disabled {
    color: ${({ theme }) => theme.neutralColor.textSecondary};
  }

  .checkbox__input{
    display: grid;
    grid-template-areas: "checkbox";

    > * {
      grid-area: checkbox;
    }

    input{
      height: 20px;
      opacity: 0;
      width: 20px;

      &:focus + .checkbox__control {
        box-shadow: 0 0 0 0.05em ${({ theme }) => theme.accentColor.primary}, 0 0 0.15em 0.1em ${({ theme }) => theme.accentColor.primary};
      }

      &:checked + .checkbox__control {
        background-color: ${({ theme }) => theme.accentColor.primary};
      }

      &:checked + .checkbox__control .icon {
        opacity: 1;
      }

      &:disabled + .checkbox__control {
        color: ${({ theme }) => theme.neutralColor.textSecondary};
      }
    }
    .checkbox__control{
      background-color: transparent;
      border: 2px solid ${({ theme }) => theme.accentColor.primary};
      border-radius: 5px;
      display: inline-grid;
      height: 20px;
      width: 20px;

      .icon{
        opacity: 0;
        margin-top: -3px;

        svg{
          width: 18px;
        }
      }
    }
  }
  .checkbox__label{
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
`;

const PaymentTypeImg = styled.img`
  user-select: none;
  max-height: 50px;
  max-width: 140px;
  height: auto;
  width: auto;
  margin-bottom: 10px;
`;

const PaymentTypePicker = (props) => {
  const {
    paymentMethods,
    error,
    realizationType,
    paymentTypeChange
  } = props;

  const { register, formState: { errors } } = useFormContext();

  const handlePaymentTypeChange = (value, name) => {
    paymentTypeChange(value, name);
  }

  return (
    <div>
      <StyledFormHeader>Wybierz formę płatności</StyledFormHeader>
      <StyledForm>
        {errors.paymentType && <StyledErrorBox err={errors.paymentType}><AlertTriangle />Wybierz metodę płatności</StyledErrorBox>}
        <PaymentTypePickerContainer>
          {
            !error
              ?
              <>
                {
                  realizationType === 'pickup'
                    ?
                    <div key="mr-ppo">
                      <CheckboxLabel error={errors.paymentType}>
                        <span className="checkbox__input">
                          <input
                            type="radio"
                            value="mr-ppo"
                            fullname="Płatnośc przy odbiorze"
                            {...register("paymentType",
                              {
                                onChange: (e) => handlePaymentTypeChange(e.target.value, e.target.getAttribute('fullname')),
                                required: 'Sposób płatności jest wymagany'
                              })} />
                          <span className="checkbox__control">
                            <span className="icon">
                              <Check />
                            </span>
                          </span>
                        </span>
                        <span className="checkbox__label">
                          <CreditCard size={46} />
                          Zapłać przy odbiorze
                        </span>
                      </CheckboxLabel>
                    </div>
                    :
                    null
                }
                {paymentMethods
                  .filter((method) => method.status === "ENABLED" && method.minAmount < 5000 ? method : null)
                  .sort((a, b) => a.value.localeCompare(b.firstname))
                  .map((method) => (
                    <div key={method.value}>
                      <CheckboxLabel error={errors.paymentType}>
                        <span className="checkbox__input">
                          <input
                            onChange={(e) => handlePaymentTypeChange(e.target.value, e.target.getAttribute('fullname'))}
                            type="radio"
                            value={method.value}
                            fullname={method.name}
                            {...register("paymentType",
                              {
                                onChange: (e) => handlePaymentTypeChange(e.target.value, e.target.getAttribute('fullname')),
                                required: 'Sposób płatności jest wymagany'
                              })} />
                          <span className="checkbox__control">
                            <span className="icon">
                              <Check />
                            </span>
                          </span>
                        </span>
                        <span className="checkbox__label">
                          <PaymentTypeImg src={`${method.brandImageUrl}`} alt={method.name} />
                          {method.name}
                        </span>
                      </CheckboxLabel>
                    </div>
                  ))}
              </>
              :
              <p>Wystąpił błąd spróbój później</p>
          }
        </PaymentTypePickerContainer>
      </StyledForm>
    </div>
  );
};

const mapStateToProps = state => ({
  realizationType: state.order.realizationType
});

const mapDispatchToProps = dispatch => ({
  paymentTypeChange: (value, name) => dispatch(actions.paymentTypeChange(value, name))
});

PaymentTypePicker.propTypes = {
  paymentTypeChange: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    brandImageUrl: PropTypes.string,
    name: PropTypes.string
  })),
  error: PropTypes.string,
  realizationType: PropTypes.string.isRequired
}

PaymentTypePicker.defaultProps = {
  error: null,
  paymentMethods: []
};


export default connect(mapStateToProps, mapDispatchToProps)(PaymentTypePicker);
