import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as actions from '../../state/actions/index';

import ResendVerificationCodeForm from '../../components/Forms/ResendVerificationCodeForm/ResendVerificationCodeForm';

const StyledContainer = styled.div`
  padding: 60px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledHeading = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
`;

const Verify = (props) => {
  const { match, verify, verified } = props;

  const history = useHistory();
  useEffect(() => {

    if (verified) {
      setTimeout(() => {
        history.push('/');
      }, 5000);
    }
  }, [verified]);

  useEffect(() => {
    verify(match.params.code);
  }, []);

  let elem;
  if (verified) {
    elem = <StyledContainer>
      <StyledHeading>Account Verified successfully!</StyledHeading>
      <div>You will be redirected to the home page in 5 seconds</div>
    </StyledContainer>;
  } else {
    elem = <StyledContainer>
      <StyledHeading>There was an error</StyledHeading>
      <div><ResendVerificationCodeForm /></div>
    </StyledContainer>;
  }

  return (
    <div>
      {elem}
    </div>
  )
};

const mapStateToProps = (state) => ({
  verified: state.verify.verified
});

const mapDispatchToProps = dispatch => ({
  verify: (code) => dispatch(actions.verify(code))
});

Verify.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  verify: PropTypes.func.isRequired,
  verified: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
