import React, { useState, useEffect } from 'react';
import { ChevronDown, Home, X } from 'react-feather';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as actions from '../../../state/actions/index';
import Loading from '../Loading/Loading';

const StyledDropdown = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 280px;
`;

const DropdownButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 16px;
  width: 100%;
  height: 56px;
  border-radius: 6px;
  color: #f9f9f9;
  background: ${({ theme }) => theme.accentColor.primary};
  border: 0;
  cursor: pointer;
  z-index: 2;
  transition: all .2s ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.accentColor.primaryHover};
  }

  & p{
    font-size: 14px;
    color: ${({ theme }) => theme.neutralColor.textSecondary};
    font-weight: 600;
  }
`;

const DropdownIcon = styled.span`
  margin-top: -1px;
`;

const DropdownToggleIcon = styled.span`
  margin-left: auto;
  margin-top: 2px;
`;

const DropdownMenu = styled.div`
  position: absolute;
  overflow: hidden;
  z-index: 1;
  top: 54px;
  left: 0px;
  width: 100%;
  opacity: ${props => props.isOpen ? 1 : 0};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 0;
  background:${({ theme }) => theme.accentColor.secondary};
  translate: ${props => props.isOpen ? '0' : '0 -20px'};
  transition: 0.4s;
  z-index: 10;
`;

const DropdownMenuButton = styled.button`
  border: 0;
  width: 100%;
  height: 56px;
  border-radius: 0;
  cursor: pointer;
  transition: all .2s ease-in-out;

  &:hover{
    background-color: #1c354b;
  }

  & p{
    font-size: 12px;
    color: ${({ theme }) => theme.neutralColor.white};
    font-weight: 600;
  }
`;


const Dropdown = (props) => {
  const {
    isLoadingRestaurants,
    restaurants,
    getRestaurantInfo,
    getHours,
    getMenuItemsOptions,
    getMenuItemsOptionsGroups,
    getMenuItems,
    getMenuCategories,
    clearCart,
    setActiveRestaurant,
    activeRestaurant
  } = props;

  const [dropdownToggled, setDropdowToggled] = useState(false);

  useEffect(() => {
    if(window.localStorage.getItem('activeRestaurant') === null && restaurants.length>0) {
      window.localStorage.setItem('activeRestaurant', restaurants[0]._id);
      setActiveRestaurant(restaurants[0]._id);
    }
    setActiveRestaurant(window.localStorage.getItem('activeRestaurant'));
  }, [restaurants])

  const handleDropdownToggle = () => {
    setDropdowToggled((prev) => !prev);
  }

  const handleRestaurantChange = (id) => {
    window.localStorage.setItem('activeRestaurant', id);
    setActiveRestaurant(id);
    if (localStorage.getItem('cart')) {
      clearCart(localStorage.getItem('cart'));
      window.localStorage.removeItem('cart');
    }
    getHours(window.localStorage.getItem('activeRestaurant'));
    getMenuItemsOptions(window.localStorage.getItem('activeRestaurant'));
    getMenuItemsOptionsGroups(window.localStorage.getItem('activeRestaurant'));
    getMenuItems(window.localStorage.getItem('activeRestaurant'));
    getMenuCategories(window.localStorage.getItem('activeRestaurant'));
    getRestaurantInfo(window.localStorage.getItem('activeRestaurant'));
    // get menu
    setDropdowToggled((prev) => !prev);
  }


  const toggleIcon = (toggled) => toggled === false ? <ChevronDown /> : <X />;

  return (
    <>
      {
        !isLoadingRestaurants
          ?
          (<StyledDropdown>
            <DropdownButton onClick={() => handleDropdownToggle()}>
              <DropdownIcon>
                <Home />
              </DropdownIcon>
              {
                restaurants.filter(item => (item._id === activeRestaurant ? item : null))
                  .map(item => (
                    <p key={item._id}>{item.name}</p>
                  ))
              }
              <DropdownToggleIcon>
                {toggleIcon(dropdownToggled)}
              </DropdownToggleIcon>
            </DropdownButton>
            <DropdownMenu isOpen={dropdownToggled}>
              {
                restaurants.filter(item => (item._id !== activeRestaurant ? item : null))
                  .map(item => (
                    <DropdownMenuButton key={item._id} onClick={() => handleRestaurantChange(item._id)}>
                      <p>{item.name}</p>
                    </DropdownMenuButton>
                  ))
              }
            </DropdownMenu>
          </StyledDropdown>)
          :
          <Loading />
      }
    </>
  );
};

const mapStateToProps = state => ({
  restaurants: state.restaurant.restaurants,
  activeRestaurant: state.restaurant.activeRestaurant,
  isLoadingRestaurants: state.restaurant.isLoadingRestaurants
});

const mapDispatchToProps = dispatch => ({
  getHours: (restaurantId) => dispatch(actions.getHours(restaurantId)),
  getRestaurantInfo: (restaurantId) => dispatch(actions.getRestaurantInfo(restaurantId)),
  getMenuItemsOptions: (restaurantId) => dispatch(actions.getMenuItemsOptions(restaurantId)),
  getMenuItemsOptionsGroups: (restaurantId) => dispatch(actions.getMenuItemsOptionsGroups(restaurantId)),
  getMenuItems: (restaurantId) => dispatch(actions.getMenuItems(restaurantId)),
  getMenuCategories: (restaurantId) => dispatch(actions.getMenuCategories(restaurantId)),
  setActiveRestaurant: (restaurantId) => dispatch(actions.setActiveRestaurant(restaurantId)),
  clearCart: (cartId) => dispatch(actions.clearCart(cartId))

});

Dropdown.propTypes = {
  getHours: PropTypes.func.isRequired,
  getMenuItemsOptions: PropTypes.func.isRequired,
  clearCart: PropTypes.func.isRequired,
  getRestaurantInfo: PropTypes.func.isRequired,
  getMenuItemsOptionsGroups: PropTypes.func.isRequired,
  getMenuItems: PropTypes.func.isRequired,
  getMenuCategories: PropTypes.func.isRequired,
  setActiveRestaurant: PropTypes.func.isRequired,
  restaurants: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      address: PropTypes.string,
      placeId: PropTypes.string,
      deliveryEnabled: PropTypes.bool,
    })
  ),
  activeRestaurant: PropTypes.string,
  isLoadingRestaurants: PropTypes.bool.isRequired,
}

Dropdown.defaultProps = {
  restaurants: [],
  activeRestaurant: null
}

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
