import axios from 'axios';
import * as actionTypes from './actionTypes';

// get Menu Categories
export const getMenuCategoriesStart = () => ({
  type: actionTypes.GET_MENU_CATEGORIES_START
});

export const getMenuCategoriesSuccess = (data) => ({
  type: actionTypes.GET_MENU_CATEGORIES_SUCCESS,
  data: data
});

export const getMenuCategoriesFail = (error) => ({
  type: actionTypes.GET_MENU_CATEGORIES_FAIL,
  error: error
});

export const getMenuCategories = (restaurantId) => dispatch => {
  dispatch(getMenuCategoriesStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/api/itemCategories/${restaurantId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(getMenuCategoriesSuccess(res.data.data));
    })
    .catch(err => {
      dispatch(getMenuCategoriesFail(err.response.data.message));
    })
};

// get Menu Items
export const getMenuItemsStart = () => ({
  type: actionTypes.GET_MENU_ITEMS_START
});

export const getMenuItemsSuccess = (data) => ({
  type: actionTypes.GET_MENU_ITEMS_SUCCESS,
  data: data
});

export const getMenuItemsFail = (error) => ({
  type: actionTypes.GET_MENU_ITEMS_FAIL,
  error: error
});

export const getMenuItems = (restaurantId) => dispatch => {
  dispatch(getMenuItemsStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/api/items/${restaurantId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(getMenuItemsSuccess(res.data.data));
    })
    .catch(err => {
      dispatch(getMenuItemsFail(err.response.data.message));
    })
};

// get Menu Items Options Groups
export const getMenuItemsOptionsGroupsStart = () => ({
  type: actionTypes.GET_MENU_ITEMS_OPTIONS_GROUPS_START
});

export const getMenuItemsOptionsGroupsSuccess = (data) => ({
  type: actionTypes.GET_MENU_ITEMS_OPTIONS_GROUPS_SUCCESS,
  data: data
});

export const getMenuItemsOptionsGroupsFail = (error) => ({
  type: actionTypes.GET_MENU_ITEMS_OPTIONS_GROUPS_FAIL,
  error: error
});

export const getMenuItemsOptionsGroups = (restaurantId) => dispatch => {
  dispatch(getMenuItemsOptionsGroupsStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/api/itemOptionsGroups/${restaurantId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(getMenuItemsOptionsGroupsSuccess(res.data.data));
    })
    .catch(err => {
      dispatch(getMenuItemsOptionsGroupsFail(err.response.data.message));
    })
};

// get Menu Items Options
export const getMenuItemsOptionsStart = () => ({
  type: actionTypes.GET_MENU_ITEMS_OPTIONS_START
});

export const getMenuItemsOptionsSuccess = (data) => ({
  type: actionTypes.GET_MENU_ITEMS_OPTIONS_SUCCESS,
  data: data
});

export const getMenuItemsOptionsFail = (error) => ({
  type: actionTypes.GET_MENU_ITEMS_OPTIONS_FAIL,
  error: error
});

export const getMenuItemsOptions = (restaurantId) => dispatch => {
  dispatch(getMenuItemsOptionsStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/api/itemsOptions/${restaurantId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(getMenuItemsOptionsSuccess(res.data.data));
    })
    .catch(err => {
      dispatch(getMenuItemsOptionsFail(err.response.data.message));
    })
};
