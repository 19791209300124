import React from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AlertTriangle } from 'react-feather';
import GPAComponent from '../OrderForm/GPAcomponent';
import * as actions from '../../../state/actions/index';

const StyledErrorBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.infoColor.danger};
  border-radius: 5px;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
  display: ${props => props.err ? 'flex' : 'none'};
  font-size: 12px;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 12px 15px;
  width: 100%;

  svg{
    margin-right: 10px;
    width: 20px;
  }
`;

const StyledFormHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
  width: 100%;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputField = styled.div`
  margin-bottom: 15px;
  position: relative;
  width: 100%;

  span{
    position: absolute;
    right: 15px;
    top: 10px;

    svg{
      width: 18px;
    }
  }

  div{
    color: ${({ theme }) => theme.infoColor.danger};
    font-size: 12px;
    font-weight: 600;
    margin: 5px 0 0 10px;
  }
`;

const StyledInput = styled.input`
  background-color: #16283f;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  padding: 12px 15px;
  transition: all 0.3s ease-in;
  width: 100%;

  ::placeholder{
    color: #B6B6BA;
    font-weight: 500;
  }

  &:focus{
    box-shadow: 0 0 0 0.05em ${({ theme }) => theme.accentColor.primary}, 0 0 0.15em 0.1em ${({ theme }) => theme.accentColor.primary};
  }

  &:focus-visible{
    outline: ${({ theme }) => theme.accentColor.primary} solid 2px;
  }

  &[type='submit']{
    background: ${({ theme }) => theme.accentColor.primary};
    color: ${({ theme }) => theme.neutralColor.textSecondary};
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    padding: 13px;


    &:hover{
      background: ${({ theme }) => theme.accentColor.secondary};
    }
  }
`;

const StyledAlertIcon = styled(AlertTriangle)`
  color: ${({ theme }) => theme.infoColor.danger};
`;

const EditDeliveryDataForm = React.forwardRef((props, ref) =>{
  const {
    addressData,
    errorResponse,
    editUserData,
    localNumber,
    userId
  } = props;

  const { register, setValue, control, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      localNumber: localNumber
    }
  });

  // useEffect(() => {
  //   reset(address, localNumber);
  // }, [address, localNumber]);

  const onSubmit = data => {
    editUserData(userId, data);
  }

  const handleAddress = (data) => {
    setValue("address", data);
  }

  return(
    <div>
      <StyledFormHeader>Twoje Dane</StyledFormHeader>

      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <div style={{ width: '100%', marginBottom: '20px' }}>
          <Controller
            name="address"
            rules={{
              required: "Adres dostawy jest wymagany"
            }}
            control={control}
            render={({ field, fieldState }) => (
              <GPAComponent
                {...field}
                error={fieldState.error}
                address={addressData}
                changed={(data) => handleAddress(data)}
                ref={ref}
              />
            )}
          />
          </div>
        <InputField>
          <StyledInput
            placeholder="np. 4"
            name="localNumber"
            {...register("localNumber", {
              maxLength: {
                value: 8,
                message: "Numer mieszkania jest zbyt długi"
              }
            })}
          />
          <span>{errors.localNumber && <StyledAlertIcon />}</span>
          <div>{errors.localNumber && errors.localNumber.message}</div>
        </InputField>
        <StyledErrorBox err={errorResponse}><AlertTriangle />{errorResponse}</StyledErrorBox>
        <StyledInput type='submit' value='Aktualizuj dane' />
      </StyledForm>
    </div>
  );
}
);

EditDeliveryDataForm.displayName = "EditDeliveryDataForm";

const mapStateToProps = state => ({
  errorResponse: state.users.updateError,
  address: state.users.address,
  localNumber: state.users.localNumber,
  userId: state.auth.userId,
  addressData: state.users.addressData
});

const mapDispatchToProps = dispatch => ({
  getAddressData: (placeId) => dispatch(actions.getAddressData(placeId)),
  editUserData: (userId, data) => dispatch(actions.editUserData(userId, data))
});

EditDeliveryDataForm.propTypes = {
  errorResponse: PropTypes.string,
  userId: PropTypes.string,
  editUserData: PropTypes.func.isRequired,
  address: PropTypes.string,
  localNumber: PropTypes.string,
  addressData: PropTypes.shape({
    value: PropTypes.shape({
      place_id: PropTypes.string
    })
  })
};

EditDeliveryDataForm.defaultProps = {
  errorResponse: null,
  userId: null,
  address: null,
  localNumber: null,
  addressData: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDeliveryDataForm);