import axios from 'axios';
import * as actionTypes from './actionTypes';

export const addCartStart = () => ({
  type: actionTypes.ADD_CART_START
});

export const addCartSuccess = (data) => ({
  type: actionTypes.ADD_CART_SUCCESS,
  data: data
});

export const addCartFail = (error) => ({
  type: actionTypes.ADD_CART_FAIL,
  error: error
});

export const addCart = (restaurantId, userId, product, options) => dispatch => {
  dispatch(addCartStart());

  const token = localStorage.getItem('token');
  axios.post(`${process.env.REACT_APP_API}/api/cart`, {
    restaurantId: restaurantId,
    userId: userId,
    product: product,
    options: options
  },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      localStorage.setItem('cart', res.data.cart._id);
      dispatch(addCartSuccess(res.data.cart));
    })
    .catch(err => {
      dispatch(addCartFail(err.response.data.message));
    })
};

export const getCartStart = () => ({
  type: actionTypes.GET_CART_START
});

export const getCartSuccess = (data) => ({
  type: actionTypes.GET_CART_SUCCESS,
  data: data
});

export const getCartFail = (error) => ({
  type: actionTypes.GET_CART_FAIL,
  error: error
});

export const getCart = (cartId, restaurantId) => dispatch => {
  dispatch(getCartStart());

  const token = localStorage.getItem('token');
  axios.get(`${process.env.REACT_APP_API}/api/cart/${cartId}/${restaurantId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      if (res.data.rightRestaurant === true) {
        dispatch(getCartSuccess(res.data));
      } else {
        localStorage.removeItem('cart');
        dispatch(getCartFail(res.data.message))
      }
    })
    .catch(err => {
      localStorage.removeItem('cart');
      dispatch(getCartFail(err.response.data.message));
    })
}

export const addToCartStart = () => ({
  type: actionTypes.ADD_TO_CART_START
});

export const addToCartSuccess = (data) => ({
  type: actionTypes.ADD_TO_CART_SUCCESS,
  data: data
});

export const addToCartFail = (error) => ({
  type: actionTypes.ADD_TO_CART_FAIL,
  error: error
});

export const addToCart = (cartId, product, options) => dispatch => {
  dispatch(addToCartStart());

  const token = localStorage.getItem('token');
  axios.put(`${process.env.REACT_APP_API}/api/cart/addtocart/${cartId}`,
    {
      product,
      options
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(addToCartSuccess(res.data));
    })
    .catch(err => {
      dispatch(addToCartFail(err.response.data.message));
    })
}

export const incrementQuantityStart = () => ({
  type: actionTypes.INCREMENT_QUANTITY_CART_START
});

export const incrementQuantitySuccess = (data) => ({
  type: actionTypes.INCREMENT_QUANTITY_CART_SUCCESS,
  data: data
});

export const incrementQuantityFail = (error) => ({
  type: actionTypes.INCREMENT_QUANTITY_CART_FAIL,
  error: error
});

export const incrementQuantity = (cartId, productId) => dispatch => {
  dispatch(incrementQuantityStart());

  const token = localStorage.getItem('token');
  axios.put(`${process.env.REACT_APP_API}/api/cart/incrementquantity/${cartId}`,
    {
      productId
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(incrementQuantitySuccess(res.data));
    })
    .catch(err => {
      dispatch(incrementQuantityFail(err.response.data.message));
    })
};

export const decrementQuantityStart = () => ({
  type: actionTypes.DECREMENT_QUANTITY_CART_START
});

export const decrementQuantitySuccess = (data) => ({
  type: actionTypes.DECREMENT_QUANTITY_CART_SUCCESS,
  data: data
});

export const decrementQuantityFail = (error) => ({
  type: actionTypes.DECREMENT_QUANTITY_CART_FAIL,
  error: error
});

export const decrementQuantity = (cartId, productId) => dispatch => {
  dispatch(decrementQuantityStart());

  const token = localStorage.getItem('token');
  axios.put(`${process.env.REACT_APP_API}/api/cart/decrementquantity/${cartId}`,
    {
      productId
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(decrementQuantitySuccess(res.data));
    })
    .catch(err => {
      dispatch(decrementQuantityFail(err.response.data.message));
    })
};

export const clearCartStart = () => ({
  type: actionTypes.CLEAR_CART_START
});

export const clearCartSuccess = (data) => ({
  type: actionTypes.CLEAR_CART_SUCCESS,
  data: data
});

export const clearCartFail = (error) => ({
  type: actionTypes.CLEAR_CART_FAIL,
  error: error
});

export const clearCart = (cartId) => dispatch => {
  dispatch(clearCartStart());

  const token = localStorage.getItem('token');
  axios.delete(`${process.env.REACT_APP_API}/api/cart/${cartId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(clearCartSuccess(res.data));
    })
    .catch(err => {
      dispatch(clearCartFail(err.response.data.message));
    })
};

export const removeFromCartStart = () => ({
  type: actionTypes.REMOVE_FROM_CART_START
});

export const removeFromCartSuccess = (data) => ({
  type: actionTypes.REMOVE_FROM_CART_SUCCESS,
  data: data
});

export const removeFromCartFail = (error) => ({
  type: actionTypes.REMOVE_FROM_CART_FAIL,
  error: error
});

export const removeFromCart = (cartId, productsIds) => dispatch => {
  dispatch(removeFromCartStart());

  const token = localStorage.getItem('token');
  axios.put(`${process.env.REACT_APP_API}/api/cart/removefromcart/${cartId}`,
    {
      productsIds
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      dispatch(removeFromCartSuccess(res.data));
    })
    .catch(err => {
      dispatch(removeFromCartFail(err.response.data.message));
    })
}
